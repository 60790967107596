<div fxLayout="row" fxLayoutAlign="center center" class="login-style">
  <mat-card class="login-card mt-10vh" fxFlex="350px" fxFlex.lt-sm="90%">
    <mat-card-header fxLayoutAlign="center center">
      <mat-card-title
        ><h1 style="color: #757575" class="mt-5">
          REGISTRARSE
        </h1></mat-card-title
      >
    </mat-card-header>
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      <mat-card-content class="auth-material-card-content">
        <p>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>Usuario</mat-label>
            <input matInput placeholder="Username" formControlName="username" />
            <mat-icon matSuffix>person</mat-icon>
            <mat-error
              *ngIf="registerForm.controls['username'].errors?.required"
              >El usuario es requerido.</mat-error
            >
            <mat-error
              *ngIf="registerForm.controls['username'].errors?.minlength"
              >Username must be at least 5 characters long.</mat-error
            >
          </mat-form-field>
        </p>

        <p>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>Correo electrónico</mat-label>
            <input matInput placeholder="Email" formControlName="email" />
            <mat-icon matSuffix>mail_outline</mat-icon>
            <mat-error *ngIf="registerForm.controls['email'].errors?.required"
              >El correo es requerido.</mat-error
            >
            <mat-error *ngIf="registerForm.controls['email'].errors?.email"
              >Correo inválido.</mat-error
            >
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>Contraseña</mat-label>
            <input
              matInput
              placeholder="Password"
              formControlName="password"
              [type]="passWordHide ? 'password' : 'text'"
            />
            <mat-icon
              matSuffix
              (click)="passWordHide = !passWordHide"
              class="cursor-pointer"
            >
              {{ passWordHide ? "visibility_off" : "visibility" }}
            </mat-icon>
            <mat-error
              *ngIf="registerForm.controls['password'].errors?.required"
              >La contraseña es requerida.</mat-error
            >
            <mat-error
              *ngIf="registerForm.controls['password'].errors?.minlength"
              >La contraseña debe tener al menos 5 caracteres.</mat-error
            >
          </mat-form-field>
        </p>

        <p>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>Confirmar Contraseña</mat-label>
            <input
              matInput
              placeholder="Confirm password"
              formControlName="confirmPassword"
              [type]="passWordHide ? 'password' : 'text'"
            />
            <mat-icon
              matSuffix
              (click)="passWordHide = !passWordHide"
              class="cursor-pointer"
            >
              {{ passWordHide ? "visibility_off" : "visibility" }}
            </mat-icon>
            <mat-error
              *ngIf="registerForm.controls['confirmPassword'].errors?.required"
              >Por favor, confirme su contraseña.</mat-error
            >
          </mat-form-field>
        </p>
      </mat-card-content>
      <mat-card-actions class="auth-material-card-actions">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <p style="font-size: 15px">
            <mat-checkbox #check (change)="toggle(check.checked)"
              ><div>
                <a href="terminos/1" target="_blank">
                  Acepto Términos y Condiciones</a
                >
              </div></mat-checkbox
            >
          </p>
        </div>
        <div fxLayout="row">
          <button
            [disabled]="!registerForm.valid || checkbox"
            mat-raised-button
            [routerLink]="['/register']"
            color="primary"
            fxFlex="100%"
          >
            REGISTRARSE
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <h3 class="mat-subheading-2 mt-10px mb-10px login-font-color">
            Ya tienes una cuenta?
          </h3>
        </div>
        <div fxLayout="row">
          <button
            mat-stroked-button
            [routerLink]="['/login']"
            fxFlex="100%"
            class="mb-10px"
          >
            INICIAR SESIÓN
          </button>
        </div>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
