import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Place } from 'src/app/models/Place';
import _ from 'lodash';
import { Delivery } from 'src/app/models/Delivery';
import { DeliveryService } from 'src/app/services/delivery.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs/internal/Observable';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CuerrentSearch } from 'src/app/models/CurrentSearch';
import { Reviews } from 'src/app/models/Reviews';
import { LoginService } from 'src/app/services/login.service';
import { DataServiceService } from 'src/app/services/data-service.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-search-courier',
  templateUrl: './search-courier.component.html',
  styleUrls: ['./search-courier.component.css'],
})
export class SearchCourierComponent implements OnInit, OnDestroy {
  firstName: string;
  searchForm: FormGroup;
  isLoading = true;
  loading = true;
  public noResultsFound = false;
  public routes: Delivery[] = [];
  sortByOptions = ['Listados más nuevos', 'Fecha de salida(mas próxima)'];
  baseUrl: string;
  id: Delivery;
  cardSelecionada: Delivery;
  selectedOrder: string;
  searchTypes: string[];
  toppingList: string[] = ['Todos', 'Dinero', 'Libras', 'Telefonos'];
  defaultTopping = ['Todos'];
  orederBy = {
    listadoMasNuevo: 'Listados más nuevos',
    fechaSalida: 'Fecha de salida(mas próxima)',
    libras: 'Precio por libra(menor-mayor)',
    dinero: 'Costo por envio de dinero(menor-mayor)',
    telefonos: 'Precio por telefono(menor-mayor)',
  };
  time: number;

  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  options: any = {
    types: ['(cities)'],
  };
  fromPlace: Place;
  toPlace: Place;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  routes$: Observable<any>;
  dataSource: MatTableDataSource<Delivery> = new MatTableDataSource<Delivery>();
  storage: CuerrentSearch;
  currentUser: any;

  constructor(
    private fb: FormBuilder,
    private login: LoginService,
    private deliveryService: DeliveryService,
    private authService: AuthService,
    private toastr: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private dataServiceService: DataServiceService
  ) {
    this.baseUrl = environment.apiUrl.replace('api/', '');
    this.time = new Date().getUTCMilliseconds();
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      from: [null, Validators.required],
      to: [null, Validators.required],
      selectedSortBy: [this.sortByOptions[0], Validators.required],
      toppings: [null],
    });
    this.searchForm.get('toppings').setValue(this.defaultTopping);
    this.populateTodos();

    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;

    this.storage = JSON.parse(localStorage.getItem('CurrentSearch'));
    if (this.storage) {
      this.searchForm.controls.from.setValue(
        this.storage.fromPlace.formattedAddress
      );
      this.searchForm.controls.to.setValue(
        this.storage.toPlace.formattedAddress
      );
      this.searchForm.controls.selectedSortBy.setValue(this.storage.orderBy);
      this.searchForm.controls.toppings.setValue(this.storage.topping);
      this.fromPlace = this.storage.fromPlace;
      this.toPlace = this.storage.toPlace;
      this.deliveryService
        .searchDeliveryRoutes(
          this.storage.fromPlace,
          this.storage.toPlace,
          this.authService.currentUserId,
          this.getSearchType()
        )
        .subscribe(
          (result: Delivery[]) => {
            this.routes = result;
            this.applySelectedOrder();
            this.routes.forEach((element, indice) => {
              if (element.toPlace.formattedAddress === null) {
                this.routes[indice].toPlace.formattedAddress =
                  this.toPlace.formattedAddress;
              }
            });
            if (this.routes.length === 0) {
              this.noResultsFound = true;
            } else {
              this.noResultsFound = false;
            }
            this.loading = false;
          },
          (error) => {
            this.toastr.error(error.message);
            this.loading = false;
          }
        );
    }
    if (this.storage === null) {
      this.loading = false;
    }
  }

  getData() {
    this.login.getLogin().subscribe((data: any) => {
      this.firstName = data.username;
    });
  }

  public handleAddressChange(address: Address, field: string) {
    if (!address.formatted_address) {
      return;
    }
    if (field === 'from') {
      this.fromPlace = this.initPlaceObj(address);
      this.searchForm.controls.from.setValue(address.formatted_address);
    } else if (field === 'to') {
      this.toPlace = this.initPlaceObj(address);
      this.searchForm.controls.to.setValue(address.formatted_address);
    }
  }

  initPlaceObj(address: Address): Place {
    const place = {} as Place;
    place.formattedAddress = address.formatted_address;
    place.googleAddressJSON = JSON.stringify(address);
    place.googlePlaceId = address.place_id;
    place.latitude = address.geometry.location.lat();
    place.longitude = address.geometry.location.lng();
    place.country = address.address_components.find((a) =>
      _.includes(a.types, 'country')
    )?.long_name;
    return place;
  }

  search() {
    this.loading = true;
    if (this.searchForm.invalid) {
      return;
    }
    if (this.dataSource) {
      this.dataSource.disconnect();
      this.routes$ = null;
    }
    this.deliveryService
      .searchDeliveryRoutes(
        this.fromPlace,
        this.toPlace,
        this.authService.currentUserId,
        this.getSearchType()
      )
      .subscribe(
        (result: Delivery[]) => {
          this.routes = result;
          this.applySelectedOrder();
          this.routes.forEach((element, indice) => {
            if (element.toPlace.formattedAddress == null) {
              this.routes[indice].toPlace.formattedAddress =
                this.toPlace.formattedAddress;
            }
          });
          const currentSearch = {
            fromPlace: this.fromPlace,
            toPlace: this.toPlace,
            orderBy: this.searchForm.controls.selectedSortBy.value,
            topping: this.searchForm.controls.toppings.value,
          } as CuerrentSearch;
          localStorage.setItem('CurrentSearch', JSON.stringify(currentSearch));

          if (this.routes.length === 0) {
            this.toastr.info('No se han encontrado resultados.');
            this.noResultsFound = true;
          } else {
            this.noResultsFound = false;
          }
          this.loading = false;
        },
        (error) => {
          this.toastr.error(error.message);
          this.loading = false;
        }
      );
  }

  changeSortByFilter() {
    this.applySelectedOrder();
  }

  ngOnDestroy() {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }

  navigate(card: Delivery) {
    this.dataServiceService.setDelivery = card;
    this.router.navigate(['/courier-details']);
  }

  getSearchType(): string[] {
    this.searchTypes = this.searchForm.controls.toppings.value;
    if (this.searchForm.controls.toppings.value?.includes('Todos')) {
      this.searchTypes = null;
      return;
    }
    if (this.searchForm.controls.toppings.value?.[0] === undefined) {
      this.searchTypes = null;
      return;
    }
    return this.searchTypes;
  }

  updateOrderByList() {
    this.sortByOptions = [
      this.orederBy.listadoMasNuevo,
      this.orederBy.fechaSalida,
    ];
    if (this.searchForm.controls.toppings.value?.includes('Todos')) {
      this.populateTodos();
    }
    if (this.searchForm.controls.toppings.value?.includes('Dinero')) {
      this.sortByOptions.push(this.orederBy.dinero);
    }
    if (this.searchForm.controls.toppings.value?.includes('Libras')) {
      this.sortByOptions.push(this.orederBy.libras);
    }
    if (this.searchForm.controls.toppings.value?.includes('Telefonos')) {
      this.sortByOptions.push(this.orederBy.telefonos);
    }
  }

  setTodos(event) {
    if (!event.panelOpen && this.sortByOptions.length === 2) {
      this.searchForm.get('toppings').setValue(this.defaultTopping);
      this.populateTodos();
    }
  }

  applySelectedOrder() {
    if (
      this.searchForm.controls.selectedSortBy.value ===
      this.orederBy.listadoMasNuevo
    ) {
      this.routes = this.routes.sort((a, b) =>
        new Date(a.createDate).getTime() < new Date(b.createDate).getTime()
          ? 1
          : -1
      );
    } else if (
      this.searchForm.controls.selectedSortBy.value === this.orederBy.libras
    ) {
      this.routes = this.routes.sort((a, b) => {
        return a.price - b.price;
      });
    } else if (
      this.searchForm.controls.selectedSortBy.value ===
      this.orederBy.fechaSalida
    ) {
      this.routes = this.routes.sort((a, b) =>
        new Date(a.departure).getTime() > new Date(b.departure).getTime()
          ? 1
          : -1
      );
    } else if (
      this.searchForm.controls.selectedSortBy.value === this.orederBy.telefonos
    ) {
      this.routes = this.routes.sort((a, b) => {
        return a.precioPorTelefono - b.precioPorTelefono;
      });
    } else if (
      this.searchForm.controls.selectedSortBy.value === this.orederBy.dinero
    ) {
      this.routes = this.routes.sort((a, b) => {
        return a.porCada - a.entrego - (b.porCada - b.entrego);
      });
    }

    this.dataSource.data = this.routes;
    this.routes$ = this.dataSource.connect();
  }

  populateTodos() {
    this.sortByOptions.push(this.orederBy.libras);
    this.sortByOptions.push(this.orederBy.telefonos);
    this.sortByOptions.push(this.orederBy.dinero);
  }
}
