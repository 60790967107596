import { Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AuthGuard } from './helpers/auth-guard';
import { ManageAccountComponent } from './main/manage-account/manage-account.component';
import { SearchCourierComponent } from './main/search-courier/search-courier.component';
import { ManageDeliveriesComponent } from './main/delivery/manage-deliveries/manage-deliveries.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthService } from './services/auth.service';
import { PasswordRecoveryComponent } from './auth/password-recovery/password-recovery.component';
import { TerminosComponent } from './footer/terminos/terminos.component';
import { CourierDetailsComponent } from './main/search-courier/courier-details/courier-details.component';
import { ChargingdeliveryBycodeComponent } from './main/delivery/chargingdelivery-bycode/chargingdelivery-bycode.component';
import { DeliveryTotalAmountComponent } from './main/delivery/delivery-total-amount/delivery-total-amount.component';

export const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'search-courier', component: SearchCourierComponent },
  { path: 'courier-details', component: CourierDetailsComponent },
  { path: 'terminos/:id', component: TerminosComponent },
  {
    path: 'manage-account',
    component: ManageAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-services',
    component: ManageDeliveriesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'password-recovery',
    component: PasswordRecoveryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'charging-delivery',
    component: ChargingdeliveryBycodeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'total-amount',
    component: DeliveryTotalAmountComponent,
    canActivate: [AuthGuard],
  },
  { path: '', component: SearchCourierComponent, pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];
