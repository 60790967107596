<mat-card style="max-width: 100%; padding: 8px; margin-bottom: 15px">
  <div>
    <div>
      <p style="font-size: 16px; margin-bottom: 5px">
        <span style="font-size: 14px; font-weight: bold">&nbsp;Salida:</span>
        {{ route?.fromPlace.formattedAddress }}
      </p>
      <div
        *ngIf="
          route?.toPlace !== null && route?.toPlace.formattedAddress !== null
        "
      >
        <p style="font-size: 16px; margin-bottom: 8px">
          <span style="font-size: 14px; font-weight: bold"> Destino:</span>
          {{ route?.toPlace.formattedAddress }}
        </p>
      </div>
      <div *ngIf="route?.toPlace === null">
        <p style="font-size: 16px; margin-bottom: 8px">
          <span style="font-size: 14px; font-weight: bold"> Destino:</span>
          {{ route?.to }}
        </p>
      </div>
      <div
        *ngIf="
          route?.toPlace !== null && route?.toPlace.formattedAddress === null
        "
      >
        <p style="font-size: 16px; margin-bottom: 8px">
          <span style="font-size: 14px; font-weight: bold"> Destino:</span>
          Toda Cuba
        </p>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div>
        <mat-card-subtitle fxLayout="row" style="margin-bottom: 0px">
          <div
            *ngIf="route?.account.profileImageVirtualPath === null"
            mat-card-avatar
            class="example-header-image"
          ></div>
          <div *ngIf="route?.account.profileImageVirtualPath !== null">
            <img
              mat-card-avatar
              src="{{ baseUrl }}{{ route?.account.profileImageVirtualPath }}?{{
                time
              }}"
            />
          </div>
          <div fxLayout="column" style="margin-left: 8px">
            <div>{{ route?.account?.displayName }}</div>
            <div class="mat-icon-div">
              <ng-container>
                <div
                  *ngIf="route?.rating !== null"
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  style="white-space: nowrap"
                >
                  <div>
                    <ngx-material-rating
                      [style.color]="'orange'"
                      [readonly]="'true'"
                      [(ngModel)]="route?.rating.valoracion"
                      [max]="5"
                    >
                    </ngx-material-rating>
                  </div>
                  <span> {{ route?.rating.valoracion }}/{{ 5 }} </span>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-card-subtitle>
      </div>
      <div>
        <span class="material-icons">
          <span class="material-icons" style="font-size: 25px; color: #85527e">
            flight_takeoff
          </span>
        </span>
      </div>
    </div>
  </div>
  <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
  <!-- <mat-divider></mat-divider> -->
  <mat-card-content style="margin-bottom: 0px; margin-top: 8px">
    <table>
      <tr *ngIf="route?.departure !== null">
        <td>Salida</td>
        <td>{{ route?.departure | date }}</td>
      </tr>
      <tr *ngIf="route?.departure === null">
        <td>Salida</td>
        <td><strong>Todos los dias</strong></td>
      </tr>
      <tr>
        <td>Precio por Libra</td>
        <td>
          <b>${{ route?.price }}</b>
        </td>
      </tr>
      <tr>
        <td>Por cada</td>
        <td>
          <b>${{ route?.porCada }}</b>
        </td>
      </tr>
      <tr>
        <td>Se Entrega</td>
        <td>
          <b>${{ route?.entrego }}</b>
        </td>
      </tr>
      <tr>
        <td>Telefonos a llevar</td>
        <td>
          <b>{{ route?.telefonosALlevar }}</b>
        </td>
      </tr>
      <tr>
        <td>Precio por telefono</td>
        <td>
          <b>${{ route?.precioPorTelefono }}</b>
        </td>
      </tr>
    </table>
  </mat-card-content>
  <mat-divider *ngIf="!isUserView"></mat-divider>
  <mat-card-actions
    fxLayoutAlign="space-between"
    style="padding: 4px"
    *ngIf="!isUserView"
  >
    <div>
      <button mat-icon-button color="warn">
        <mat-icon aria-label="Delete post" (click)="delete()">delete</mat-icon>
      </button>
      <button mat-icon-button color="primary">
        <mat-icon aria-label="Edit post" (click)="edit()">edit</mat-icon>
      </button>
    </div>
    <div>
      <button mat-button color="primary" (click)="post()">{{ mensaje }}</button>
    </div>
  </mat-card-actions>
</mat-card>
