import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Reviews } from 'src/app/models/Reviews';
import { AuthService } from 'src/app/services/auth.service';
import { ReviewsService } from 'src/app/services/reviews.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-review',
  templateUrl: './add-review.component.html',
  styleUrls: ['./add-review.component.css'],
})
export class AddReviewComponent implements OnInit {
  public form: FormGroup;
  userId: number;
  rating = [
    {
      value: 0,
      max: 5,
      color: "'orange'",
    },
  ];

  constructor(
    private dialogRef: MatDialogRef<AddReviewComponent>,
    private fb: FormBuilder,
    private reviewsService: ReviewsService,
    private authService: AuthService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) {
    this.userId = data;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      comentario: [null, Validators.required],
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  save() {
    const review = this.form.value as Reviews;
    review.reviewCreatorUserId = this.authService.currentUserId;
    review.userId = this.userId;
    review.valoracion = this.rating[0].value;

    this.reviewsService.createReviews(review).subscribe(
      () => {
        this.toastr.success('La valoración se ha agregado satisfactoriamente');
        this.dialogRef.close();
      },
      (error) => {
        this.toastr.error(
          'Hubo un error creando su valoracion. Por favor intente otra ves.'
        );
      }
    );
  }
}
