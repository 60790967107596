<div class="form-margin">
  <form [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%">
      <div>
        <h1>Introduzca los códigos:</h1>
      </div>
      <div class="mr-left">
        <button
          mat-mini-fab
          color="primary"
          style="background-color: #3babd0 !important"
          (click)="volver()"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
    </div>
    <div formArrayName="deliveryCode">
      <div *ngFor="let code of deliveryCode.controls; let i = index">
        <div fxLayout="row">
          <div [formGroupName]="i">
            <mat-form-field appearance="outline">
              <mat-label>Código {{ i + 1 }}:</mat-label>
              <input
                matInput
                type="text"
                #codeInput
                placeholder="Introduzca el codigo"
                formControlName="deliveryCodeInput"
                (focusout)="verifyCode(codeInput.value, i)"
                autocomplete="off"
              />
            </mat-form-field>
            <mat-error
              *ngIf="
                code.status === 'INVALID' &&
                code.touched &&
                code.value.deliveryCodeInput !== ''
              "
              >El codigo es incorrecto o ya fue utilizado</mat-error
            >
          </div>
          <div style="margin-top: 10px">
            <button mat-icon-button color="primary">
              <mat-icon
                aria-label="Agregar otro codigo"
                (click)="addDeliveryCode()"
                >add</mat-icon
              >
            </button>
            <button mat-icon-button color="warn">
              <mat-icon
                aria-label="Borrar codigo"
                (click)="deleteDeliveryCode(i)"
                >delete</mat-icon
              >
            </button>
          </div>
        </div>
      </div>
      <div>
        <button
          mat-raised-button
          color="primary"
          style="background-color: #3babd0 !important"
          (click)="sendCodes()"
          [disabled]="!inputVacio || !inputError"
        >
          Enviar Codigos
        </button>
      </div>
    </div>
  </form>
</div>
