import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css'],
})
export class ConfirmationDialogComponent implements OnInit {
  message: string;
  confirmButtonText = 'Yes';
  cancelButtonText = 'Cancel';
  alert: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {}

  ngOnInit() {
    if (this.data) {
      this.message = this.data.message || this.message;
      this.alert = this.data.alert;
      if (this.data.buttonText) {
        this.confirmButtonText =
          this.data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText =
          this.data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }
}
