import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  passWordHide = true;
  checkbox = true;
  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private authService: AuthService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(4)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(5)]],
      confirmPassword: ['', [Validators.required]]
    });
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {
    if (this.registerForm.invalid){
      this.toastr.error('Invalid input. Please correct any errors and try again.');
      return;
    }

    if (this.f.password.value !== this.f.confirmPassword.value) {
      this.toastr.error('The passwords you entered do not match. Please correct any errors and try again.');
      return;
    }

    this.authService.register(this.f.username.value, this.f.password.value, this.f.email.value).subscribe(
      data => {
        this.toastr.success('User registered successfully.', 'Please Sign In!');
        this.router.navigate(['/login']);
      },
      error => {
        this.toastr.error( error.error);
      }
    );
  }

  toggle(event: any) {
    this.checkbox = !event;
  }
}
