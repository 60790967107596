import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Delivery } from '../models/Delivery';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Place } from '../models/Place';
import { UpdateIsActiveDelivery } from '../models/updateIsActiveDelivery';

@Injectable({
  providedIn: 'root',
})
export class DeliveryService {
  private baseUrl = environment.apiUrl;
  private controllerName = 'delivery';
  constructor(private http: HttpClient) {}

  searchDeliveryRoutes(
    fromPlace: Place,
    toPlace: Place,
    userId: number | null,
    searchType: string[]
  ): Observable<Delivery[]> {
    return this.http
      .post(this.baseUrl + this.controllerName + '/search', {
        fromPlace,
        toPlace,
        userId: userId ?? -1,
        searchType,
        ExcludeResultsForUserId: userId === null ? false : true,
      })
      .pipe(map((deliveries: Delivery[]) => deliveries));
  }

  getDeliveriesByUserId(userId: number): Observable<Delivery[]> {
    return this.http
      .get(this.baseUrl + this.controllerName + '/user/' + userId)
      .pipe(map((result: Delivery[]) => result));
  }

  addNewDelivery(model: Delivery): Observable<number> {
    return this.http
      .post(this.baseUrl + this.controllerName, model)
      .pipe(map((deliveryId: number) => deliveryId));
  }

  updateDelivery(model: Delivery): Observable<any> {
    return this.http.put(this.baseUrl + this.controllerName, model);
  }

  deleteDelivery(deliveryId: number): Observable<any> {
    return this.http.delete(
      this.baseUrl + this.controllerName + '/' + deliveryId
    );
  }

  updateIsActive(model: UpdateIsActiveDelivery): Observable<any> {
    return this.http.post(
      this.baseUrl + this.controllerName + '/updateIsActive',
      model
    );
  }

  updateAmountMoneyPoundsAndPhone(model: Delivery): Observable<any> {
    return this.http.post(
      this.baseUrl + this.controllerName + '/updateMoneyPoundsAndPhone',
      model
    );
  }
}
