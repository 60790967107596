import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

constructor(private http: HttpClient) { }

  getLogin(){
    return this.http.get<any>('https://localhost:44324/api/values');
  }
}
