import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DeliverySaleCodeDto } from 'src/app/models/DeliverySaleCodeDto';
import { AuthService } from 'src/app/services/auth.service';
import { DeliverySaleService } from 'src/app/services/delivery-sale.service';
import { DeliveryCodeconfirmationDialogComponent } from '../delivery-codeconfirmation-dialog/delivery-codeconfirmation-dialog.component';

@Component({
  selector: 'app-chargingdelivery-bycode',
  templateUrl: './chargingdelivery-bycode.component.html',
  styleUrls: ['./chargingdelivery-bycode.component.css'],
})
export class ChargingdeliveryBycodeComponent implements OnInit {
  form: FormGroup;
  codeArray: string[] = [];
  inputVacio: boolean;
  inputError: boolean;

  constructor(
    private fb: FormBuilder,
    private deliverySaleService: DeliverySaleService,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      deliveryCode: this.fb.array([]),
    });
    this.addDeliveryCode();
  }

  get deliveryCode(): FormArray {
    return this.form.get('deliveryCode') as FormArray;
  }

  addDeliveryCode() {
    const code = this.fb.group({
      deliveryCodeInput: new FormControl('', Validators.required),
    });
    this.deliveryCode.push(code);
    this.validarInputVacio(this.deliveryCode);
    this.validarInputError(this.deliveryCode);
  }

  deleteDeliveryCode(indice: number) {
    this.deliveryCode.removeAt(indice);
    this.validarInputVacio(this.deliveryCode);
    this.validarInputError(this.deliveryCode);
  }

  sendCodes() {
    let pago = 0;
    var codes = this.form.getRawValue();
    let codesDuplicados = [];
    codes.deliveryCode.forEach((element) => {
      codesDuplicados.push(element.deliveryCodeInput);
    });
    const tempArray = [...codesDuplicados].sort();
    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i + 1] === tempArray[i]) {
        this.toastr.error('Existen codigos duplicados, revise nuevamente.');
        pago = 1;
        break;
      }
    }
    if (pago === 0) {
      const parametro: DeliverySaleCodeDto = {
        codes: codesDuplicados,
        userId: this.authService.currentUserId,
      };
      this.deliverySaleService.sendDeliveryCodesPayment(parametro).subscribe(
        (response) => {
          const dialogRef = this.dialog.open(
            DeliveryCodeconfirmationDialogComponent,
            {
              width: '45%',
              data: response,
              disableClose: true,
            }
          );
          dialogRef.afterClosed().subscribe((result) => {
            this.deliveryCode.clear();
            this.addDeliveryCode();
          });
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
    }
  }

  verifyCode(code: string, index: number) {
    if (code != '') {
      this.inputVacio = true;
      this.codeArray.push(code);
      const parametro: DeliverySaleCodeDto = {
        codes: this.codeArray,
        userId: this.authService.currentUserId,
      };
      this.deliverySaleService
        .validateDeliverySaleCode(parametro)
        .subscribe((data) => {
          if (!data) {
            this.toastr.error('El codigo es incorrecto.');
            this.inputError = false;
            this.codeArray.shift();
            (<FormArray>this.form.get('deliveryCode')).controls[
              index
            ].setErrors(Validators.required);
          } else {
            this.codeArray.shift();
            this.validarInputVacio(this.deliveryCode);
            this.validarInputError(this.deliveryCode);
          }
        });
    }
  }

  validarInputVacio(delivery: FormArray): boolean {
    this.inputVacio = true;
    delivery.value.forEach((element) => {
      if (element.deliveryCodeInput === '') {
        this.inputVacio = false;
      }
    });
    return this.inputVacio;
  }

  validarInputError(delivery: FormArray): boolean {
    this.inputError = true;
    delivery.controls.forEach((element) => {
      if (element.status === 'INVALID') {
        this.inputError = false;
      }
    });
    return this.inputError;
  }

  volver() {
    this.router.navigate(['/manage-services']);
  }
}
