<div>
  <mat-toolbar color="primary">
    <div fxFlex.gt-sm="20%"></div>
    <a [routerLink]="['/search-courier']">
      <img class="logo-img" src="/assets/Horizontal.svg" alt="logo img" />
    </a>
    <span fxFlex></span>
    <button mat-icon-button fxHide fxShow.lt-md [matMenuTriggerFor]="mainMenu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #mainMenu="matMenu" xPosition="before" fxHide>
      <div *ngIf="isLoggedIn; else signedOut">
        <a [routerLink]="['/search-courier']" mat-menu-item>
          <mat-icon matSuffix>near_me</mat-icon
          ><span class="login">Buscar </span>
        </a>
        <a [routerLink]="['/manage-services']" mat-menu-item>
          <mat-icon matSuffix>public</mat-icon
          ><span class="login">Crear Viaje</span>
        </a>
        <a [routerLink]="['/manage-account']" mat-menu-item>
          <mat-icon matSuffix>person</mat-icon>
          <span class="login">Perfil</span>
        </a>
        <a [routerLink]="['/login']" mat-menu-item (click)="logout()">
          <mat-icon matSuffix>logout</mat-icon>
          <span class="login">Cerrar sesión</span>
        </a>
      </div>
      <ng-template #signedOut fxHide="true">
        <a [routerLink]="['/search-courier']" mat-menu-item>
          <mat-icon matSuffix>near_me</mat-icon>
          <span class="login">Buscar </span>
        </a>
        <a [routerLink]="['/register']" mat-menu-item>
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-icon matSuffix>how_to_reg</mat-icon>
            <span class="login"> Registrarse </span>
          </div>
        </a>
        <a [routerLink]="['/login']" mat-menu-item>
          <mat-icon matSuffix>login</mat-icon>
          <span class="login"> Entrar </span>
        </a>
      </ng-template>
    </mat-menu>

    <div fxShow fxHide.lt-md>
      <div *ngIf="isLoggedIn; else loggedOut">
        <a [routerLink]="['/search-courier']" mat-button>
          <mat-icon matSuffix>near_me</mat-icon
          ><span class="login">Buscar </span>
        </a>
        <a [routerLink]="['/manage-services']" mat-button>
          <mat-icon matSuffix>public</mat-icon
          ><span class="login">Crear Viaje</span>
        </a>
        <a [matMenuTriggerFor]="menu" mat-button>
          <mat-icon matSuffix>manage_accounts</mat-icon>
          <span class="login">Cuenta </span>
        </a>
        <mat-menu #menu="matMenu" xPosition="before">
          <button mat-menu-item [routerLink]="['/manage-account']">
            <mat-icon>person</mat-icon>
            <span>Perfil</span>
          </button>
          <button mat-menu-item [routerLink]="['/login']" (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span>Cerrar sesión</span>
          </button>
        </mat-menu>
      </div>
      <ng-template #loggedOut>
        <div fxLayout="row">
          <a [routerLink]="['/search-courier']" mat-button>
            <mat-icon matSuffix>near_me</mat-icon
            ><span class="login">Buscar </span>
          </a>
          <a [routerLink]="['/register']" mat-button>
            <mat-icon matSuffix>how_to_reg</mat-icon>
            <span class="login"> Registrarse </span>
          </a>
          <a [routerLink]="['/login']" mat-button>
            <mat-icon matSuffix>login</mat-icon>
            <span class="login"> Entrar </span>
          </a>
        </div>
      </ng-template>
    </div>
    <div fxFlex.gt-sm="20%"></div>
  </mat-toolbar>
</div>
