import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Delivery } from 'src/app/models/Delivery';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { AddOrEditDeliveryDialogComponent } from '../add-or-edit-delivery/add-or-edit-delivery-dialog.component';

@Component({
  selector: 'app-manage-services',
  templateUrl: './manage-deliveries.component.html',
  styleUrls: ['./manage-deliveries.component.scss'],
})
export class ManageDeliveriesComponent implements OnInit {
  isLoading = true;
  selectedOrder: string;
  public routes: Delivery[] = [];
  deliverlyListOrder = ['Listados más nuevos', 'Listados más viejos'];
  isProfileSetup: boolean;
  visible = environment.hidden;

  constructor(
    private dialog: MatDialog,
    private deliveryService: DeliveryService,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.authService.currentUser$.subscribe((user: User) => {
      this.isProfileSetup = user?.isProfileSetup;
    });
  }

  ngOnInit() {
    this.deliveryService
      .getDeliveriesByUserId(this.authService.currentUserId)
      .subscribe(
        (result: Delivery[]) => {
          this.routes = result;
          this.routes.forEach((element, indice) => {
            if (element.toPlace.formattedAddress == null) {
              this.routes[indice].toPlace.formattedAddress = 'Toda Cuba';
            }
          });
          this.selectedOrder = this.deliverlyListOrder[0];
          this.isLoading = false;
        },
        (error) => {
          this.toastr.error(error.message);
          this.isLoading = false;
        }
      );
  }

  changeDeliveryOrder() {
    if (this.selectedOrder === this.deliverlyListOrder[0]) {
      this.routes = this.routes.sort((a, b) =>
        new Date(a.deliveryDate).getTime() > new Date(b.deliveryDate).getTime()
          ? 1
          : -1
      );
    } else if (this.selectedOrder === this.deliverlyListOrder[1]) {
      this.routes = this.routes.sort((a, b) =>
        new Date(a.deliveryDate).getTime() < new Date(b.deliveryDate).getTime()
          ? 1
          : -1
      );
    }
  }

  addNewRoute(): void {
    if (!this.isProfileSetup) {
      const confirmatioDialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        {
          data: {
            message:
              'Para agregar un nuevo servicio de entrega, debe crear un perfil.¿Te gustaría crear un perfil?',
            buttonText: {
              ok: 'Si',
              cancel: 'No',
            },
          },
        }
      );
      confirmatioDialogRef.afterClosed().subscribe((updateProfile: boolean) => {
        if (updateProfile) {
          this.router.navigate(['/manage-account']);
        }
      });
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      const dialogRef = this.dialog.open(
        AddOrEditDeliveryDialogComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe((delivery: Delivery) => {
        if (delivery === null) {
          return;
        }
        this.deliveryService
          .getDeliveriesByUserId(this.authService.currentUserId)
          .subscribe(
            (result: Delivery[]) => {
              this.routes = result;
              delivery.account = this.routes[0].account;
              delivery.rating = this.routes[0].rating;
            },
            (error) => {
              this.toastr.error(error.message);
              this.isLoading = false;
            }
          );
        this.routes.push(delivery);
      });
    }
  }

  deleteDeliveryRoute($event: Delivery) {
    this.routes = this.routes.filter((d) => d.deliveryId !== $event.deliveryId);
  }

  chargingDelivery() {
    this.router.navigate(['/charging-delivery']);
  }

  totalAmount() {
    this.router.navigate(['/total-amount']);
  }
}
