<div fxLayout="column">
  <div fxLayout="row" fxFlex="100%" class="justify-end">
    <button
      class="close-modal-button"
      mat-icon-button
      class="close-button cdk-focused"
      (click)="closeDialog()"
    >
      <mat-icon class="icon-close" color="warn">close</mat-icon>
    </button>
  </div>

  <div fxFlex="100%" fxLayout="row" *ngIf="isNewDelivery; else editForm">
    <mat-horizontal-stepper #stepper>
      <mat-step [stepControl]="form">
        <ng-template matStepLabel>Viaje</ng-template>
        <mat-dialog-content fxLayout="column" [formGroup]="form">
          <div
            fxLayout.gt-sm="row"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxFlex="1 0 auto"
          >
            <mat-form-field fxFlex="50" class="pr-4">
              <input
                type="text"
                matInput
                ngx-google-places-autocomplete
                [options]="options"
                #placesRef="ngx-places"
                (onAddressChange)="handleAddressChange($event, 'from')"
                placeholder="Ciudad de Salida"
                formControlName="from"
              />
              <mat-icon matSuffix>flight_takeoff</mat-icon>
              <mat-error *ngIf="form.controls['from'].errors?.required">
                La ciudad de salida es requerida.
              </mat-error>
            </mat-form-field>
            <div fxLayout.gt-sm="row" fxLayout="row" class="form-widht">
              <mat-form-field fxFlex="100" class="form-mr">
                <input
                  matInput
                  ngx-google-places-autocomplete
                  [options]="options"
                  #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event, 'to')"
                  placeholder="Ciudad de Destino"
                  formControlName="to"
                />
                <mat-icon matSuffix>flight_land</mat-icon>
                <mat-error *ngIf="form.controls['to'].errors?.required">
                  La ciudad destino es requerida.
                </mat-error>
              </mat-form-field>
              <div class="checkbox-margin">
                <mat-checkbox
                  [checked]="checkboxCity"
                  (change)="toggleCity($event.checked)"
                >
                  Toda Cuba
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div
            fxLayout.gt-sm="row"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxFlex="1 0 auto"
            [hidden]="visible"
          >
            <mat-form-field fxFlex="50" class="pr-4">
              <input
                autocomplete="off"
                matInput
                placeholder="Dirección de entrega del paquete"
                formControlName="parcelDropoffAddress"
              />
              <mat-icon matSuffix>location_on</mat-icon>
            </mat-form-field>
            <mat-form-field fxFlex="50" class="pr-4">
              <input
                autocomplete="off"
                matInput
                placeholder="Dirección de recojida en el destino"
                formControlName="parcelPickupAddress"
              />
              <mat-icon matSuffix>location_on</mat-icon>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field fxFlex="50" class="pr-4">
              <input
                (click)="departureDatePicker.open()"
                autocomplete="off"
                matInput
                placeholder="Fecha de Salida"
                [matDatepicker]="departureDatePicker"
                formControlName="departure"
                [min]="minDepartureDate"
                [disabled]="disabled"
              />
              <mat-datepicker-toggle matSuffix [for]="departureDatePicker">
              </mat-datepicker-toggle>
              <mat-datepicker
                startView="year"
                [startAt]="dateNow"
                #departureDatePicker
              >
              </mat-datepicker>
              <mat-error *ngIf="form.controls['departure'].errors?.required"
                >La fecha de salida es requerida.</mat-error
              >
            </mat-form-field>
            <div class="checkbox-dias">
              <mat-checkbox
                [checked]="checkboxDays"
                (change)="toggleDays($event.checked)"
              >
                Todos los Dias
              </mat-checkbox>
            </div>
          </div>
          <div
            fxLayout.gt-sm="row"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxFlex="1 0 auto"
          >
            <mat-form-field fxFlex="50" class="pr-4">
              <input
                autocomplete="off"
                matInput
                placeholder="Precio por Libra"
                formControlName="price"
              />
              <mat-icon matSuffix>attach_money</mat-icon>
              <mat-error *ngIf="form.controls['price'].errors?.required"
                >Price is required.</mat-error
              >
              <mat-error *ngIf="form.controls['price'].hasError('pattern')">
                Solo puede tener números.
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50" class="pr-4">
              <mat-label>Libras Disponibles</mat-label>
              <input
                autocomplete="off"
                matInput
                placeholder="Libras disponibles a llevar:"
                formControlName="librasDisponibles"
              />
              <mat-icon matSuffix>scale</mat-icon>
              <mat-error
                *ngIf="form.controls['librasDisponibles'].errors?.required"
              >
                El campo no puede estar vacio.</mat-error
              >
              <mat-error
                *ngIf="form.controls['librasDisponibles'].hasError('pattern')"
              >
                Solo puede tener números.
              </mat-error>
            </mat-form-field>
          </div>
          <div
            fxLayout.gt-sm="row"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxFlex="1 0 auto"
            [hidden]="visible"
          >
            <mat-form-field fxFlex="50" class="pr-4">
              <input
                autocomplete="off"
                matInput
                placeholder="Dinero máximo a llevar:"
                formControlName="dineroDisponible"
              />
              <mat-icon matSuffix>attach_money</mat-icon>
              <mat-error
                *ngIf="form.controls['dineroDisponible'].hasError('pattern')"
              >
                Solo puede tener números.
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50" class="pr-4">
              <input
                autocomplete="off"
                matInput
                placeholder="Entrega minima de dinero"
                formControlName="entregaMinima"
              />
              <mat-icon matSuffix>attach_money</mat-icon>
              <mat-error
                *ngIf="form.controls['entregaMinima'].hasError('pattern')"
              >
                Solo puede tener números.
              </mat-error>
            </mat-form-field>
          </div>

          <div
            fxLayout.gt-sm="row"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxFlex="1 0 auto"
          >
            <mat-form-field fxFlex="50" class="pr-4">
              <input
                autocomplete="off"
                matInput
                placeholder="Cantidad de telefonos a llevar"
                formControlName="telefonosALlevar"
              />
              <mat-icon matSuffix>smartphone</mat-icon>
              <mat-error
                *ngIf="form.controls['telefonosALlevar'].hasError('pattern')"
              >
                Solo puede tener números.
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50" class="pr-4">
              <input
                type="number"
                autocomplete="off"
                matInput
                placeholder="Precio por cada telefono"
                formControlName="precioPorTelefono"
              />
              <mat-icon matSuffix>attach_money</mat-icon>
              <mat-error
                *ngIf="form.controls['precioPorTelefono'].hasError('pattern')"
              >
                Solo puede tener números.
              </mat-error>
            </mat-form-field>
          </div>
          <div
            fxLayout.gt-sm="row"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxFlex="1 0 auto"
          >
            <div fxLayout.gt-sm="row" fxLayout="column" class="form-widht">
              <mat-form-field class="form-mr">
                <input
                  type="number"
                  autocomplete="off"
                  matInput
                  placeholder="Por Cada:"
                  formControlName="porCada"
                />
                <mat-icon matSuffix>attach_money</mat-icon>
                <mat-error *ngIf="form.controls['porCada'].hasError('pattern')">
                  Solo puede tener números.
                </mat-error>
              </mat-form-field>
              <mat-form-field class="form-mr">
                <input
                  type="number"
                  autocomplete="off"
                  matInput
                  placeholder="Entrego:"
                  formControlName="entrego"
                />
                <mat-icon matSuffix>attach_money</mat-icon>
                <mat-error *ngIf="form.controls['entrego'].hasError('pattern')">
                  Solo puede tener números.
                </mat-error>
              </mat-form-field>
            </div>
            <mat-form-field fxFlex="50" class="pr-4">
              <input
                matInput
                placeholder="Horario Disponible (opcional)"
                formControlName="hoursAvailable"
              />
              <mat-icon matSuffix>schedule</mat-icon>
            </mat-form-field>
          </div>
          <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="1 0 auto">
            <mat-form-field
              class="example-full-width"
              appearance="standard"
              style="width: 100%"
            >
              <mat-label>Agrega un comentario</mat-label>
              <textarea
                matInput
                placeholder="Agrega un comentario"
                formControlName="comment"
              ></textarea>
            </mat-form-field>
          </div>
        </mat-dialog-content>
        <mat-dialog-actions
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="5px"
        >
          <div>
            <button mat-icon-button color="warn" *ngIf="!isNewDelivery">
              <mat-icon aria-label="Delete post" (click)="delete()"
                >delete</mat-icon
              >
            </button>
          </div>
          <div>
            <button mat-raised-button (click)="closeDialog()">Cerrar</button>
            <button
              mat-raised-button
              color="primary"
              matStepperNext
              [disabled]="
                !form.valid || (!checkboxDays && !form.controls.departure.value)
              "
            >
              Siguiente
            </button>
          </div>
        </mat-dialog-actions>
      </mat-step>
      <mat-step [stepControl]="formPayment">
        <ng-template matStepLabel>Pago</ng-template>
        <mat-dialog-content>
          <div class="pricing-item">
            <div class="pricing-header">
              <h1 class="pricing-header-title pricing-text-mb">
                Oferta especial por tiempo limitado!
              </h1>
              <div class="price-display">
                <!-- <div class="price-discount-mr">
                  <h3 class="pricing-header-discount">$29.99</h3>
                </div> -->
                <div class="pricing-price-m">
                  <h3 class="pricing-header-price">$0</h3>
                </div>
              </div>
            </div>
            <div class="product-details-wrapper">
              <h2 class="product-detail-header pricing-text-mb">
                Cuando usted crea un viaje puede:
              </h2>
              <ul class="product-items-wrapper">
                <li type="disc">
                  <h3 class="pricing-text-mb">
                    Alcanzar a un mayor numero de personas interesadas en
                    comprar sus libras u otros productos
                  </h3>
                </li>
                <li type="disc">
                  <h3 class="pricing-text-mb">
                    Encontrar usuarios que desean enviar paquetes a la ciudad
                    donde viaja o cerca
                  </h3>
                </li>
                <li type="disc">
                  <h3 class="pricing-text-mb">
                    Informar a otros usuarios sobre la ciudad de salida y ciudad
                    de destino para facilitar la entrega o recojida de los
                    paquetes
                  </h3>
                </li>
                <li type="disc">
                  <h3 class="pricing-text-mb">Editar su viaje por un mes</h3>
                </li>
              </ul>
            </div>
          </div>
        </mat-dialog-content>
        <mat-dialog-actions>
          <div fxLayout="row" fxLayoutAlign="end center" class="form-widht">
            <button mat-raised-button matStepperPrevious>Volver</button>
            <button
              mat-raised-button
              color="primary"
              matStepperNext
              (click)="save()"
            >
              Crear y Finalizar
            </button>
          </div>
        </mat-dialog-actions>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Final</ng-template>
        <div
          class="success-creating-trip-card"
          *ngIf="successCreatingTrip; else errorCreatingTrip"
        >
          <div>
            <mat-icon class="success-creating-trip-img">done_outline</mat-icon>
          </div>
          <div>
            <h1 class="success-creating-trip-msg">
              El viaje se ha creado con exito. Gracias!
            </h1>
          </div>
        </div>
        <ng-template #errorCreatingTrip>
          <div class="error-creating-trip-card">
            <div>
              <mat-icon class="error-creating-trip-icon">warning</mat-icon>
            </div>
            <div>
              <h1 class="error-creating-trip-msg">
                Hubo un error creando su viaje. Por favor intente de nuevo.
              </h1>
            </div>
          </div>
        </ng-template>
        <div class="close-end-card-button">
          <button color="primary" mat-button (click)="closeDialog()">
            Cerrar
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>

  <!--
  -------------------------------------------------
  ------------------------------ EDIT -------------
  -------------------------------------------------
 -->

  <ng-template #editForm>
    <div fxFlex="100">
      <div>
        <h2>Edite la información del viaje:</h2>
        <mat-divider class="divider"></mat-divider>
      </div>
      <mat-dialog-content fxLayout="column" [formGroup]="form">
        <div
          fxLayout.gt-sm="row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto"
        >
          <mat-form-field fxFlex="50" class="pr-4">
            <input
              matInput
              ngx-google-places-autocomplete
              [options]="options"
              #placesRef="ngx-places"
              (onAddressChange)="handleAddressChange($event, 'from')"
              placeholder="Ciudad de Salida"
              formControlName="from"
            />
            <mat-icon matSuffix>flight_takeoff</mat-icon>
            <mat-error *ngIf="form.controls['from'].errors?.required">
              La ciudad de salida es requerida.
            </mat-error>
          </mat-form-field>
          <div fxLayout.gt-sm="row" fxLayout="row" class="form-widht">
            <mat-form-field fxFlex="100" class="form-mr">
              <input
                matInput
                ngx-google-places-autocomplete
                [options]="options"
                #placesRef="ngx-places"
                (onAddressChange)="handleAddressChange($event, 'to')"
                placeholder="Ciudad de Destino"
                formControlName="to"
              />
              <mat-icon matSuffix>flight_land</mat-icon>
              <mat-error *ngIf="form.controls['to'].errors?.required">
                La ciudad destino es requerida.
              </mat-error>
            </mat-form-field>
            <div class="checkbox-margin">
              <mat-checkbox
                [checked]="checkboxCity"
                (change)="toggleCity($event.checked)"
              >
                Toda Cuba
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div
          fxLayout.gt-sm="row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto"
          [hidden]="visible"
        >
          <mat-form-field fxFlex="50" class="pr-4">
            <input
              matInput
              placeholder="Dirección de entrega del paquete"
              formControlName="parcelDropoffAddress"
            />
          </mat-form-field>
          <mat-form-field fxFlex="50" class="pr-4">
            <input
              matInput
              placeholder="Dirección de recojida en el destino"
              formControlName="parcelPickupAddress"
            />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
          <mat-form-field fxFlex="50" class="pr-4">
            <input
              (click)="departureDatePicker.open()"
              autocomplete="off"
              matInput
              placeholder="Fecha de Salida"
              [matDatepicker]="departureDatePicker"
              formControlName="departure"
              [min]="minDepartureDate"
              [disabled]="disabled"
            />
            <mat-datepicker-toggle matSuffix [for]="departureDatePicker">
            </mat-datepicker-toggle>
            <mat-datepicker
              startView="year"
              [startAt]="dateNow"
              #departureDatePicker
            >
            </mat-datepicker>
            <mat-error *ngIf="form.controls['departure'].errors?.required"
              >La fecha de salida es requerida.</mat-error
            >
          </mat-form-field>
          <div class="checkbox-dias">
            <mat-checkbox
              [checked]="checkboxDays"
              (change)="toggleDays($event.checked)"
            >
              Todos los Dias
            </mat-checkbox>
          </div>
        </div>
        <div
          fxLayout.gt-sm="row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto"
        >
          <mat-form-field fxFlex="50" class="pr-4">
            <input
              autocomplete="off"
              matInput
              placeholder="Precio por Libra"
              formControlName="price"
            />
            <mat-error *ngIf="form.controls['price'].errors?.required"
              >Price is required.</mat-error
            >
            <mat-error *ngIf="form.controls['price'].hasError('pattern')">
              Solo puede tener números.
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50" class="pr-4">
            <mat-label>Libras Disponibles</mat-label>
            <input
              autocomplete="off"
              matInput
              placeholder="Libras disponibles a llevar:"
              formControlName="librasDisponibles"
            />
            <mat-icon matSuffix>scale</mat-icon>
            <mat-error
              *ngIf="form.controls['librasDisponibles'].errors?.required"
            >
              El campo no puede estar vacio.</mat-error
            >
            <mat-error
              *ngIf="form.controls['librasDisponibles'].hasError('pattern')"
            >
              Solo puede tener números.
            </mat-error>
          </mat-form-field>
        </div>
        <div
          fxLayout.gt-sm="row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto"
          [hidden]="visible"
        >
          <mat-form-field fxFlex="50" class="pr-4">
            <input
              autocomplete="off"
              matInput
              placeholder="Dinero máximo a llevar:"
              formControlName="dineroDisponible"
            />
            <mat-icon matSuffix>attach_money</mat-icon>
            <mat-error
              *ngIf="form.controls['dineroDisponible'].hasError('pattern')"
            >
              Solo puede tener números.
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50" class="pr-4">
            <input
              matInput
              placeholder="Entrega minima de dinero"
              formControlName="entregaMinima"
            />
            <mat-icon matSuffix>attach_money</mat-icon>
            <mat-error
              *ngIf="form.controls['entregaMinima'].hasError('pattern')"
            >
              Solo puede tener números.
            </mat-error>
          </mat-form-field>
        </div>
        <div
          fxLayout.gt-sm="row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto"
        >
          <mat-form-field fxFlex="50" class="pr-4">
            <input
              matInput
              placeholder="Cantidad de telefonos a llevar"
              formControlName="telefonosALlevar"
            />
            <mat-icon matSuffix>smartphone</mat-icon>
            <mat-error
              *ngIf="form.controls['telefonosALlevar'].hasError('pattern')"
            >
              Solo puede tener números.
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50" class="pr-4">
            <input
              matInput
              placeholder="Precio por cada telefono"
              formControlName="precioPorTelefono"
            />
            <mat-icon matSuffix>attach_money</mat-icon>
            <mat-error
              *ngIf="form.controls['precioPorTelefono'].hasError('pattern')"
            >
              Solo puede tener números.
            </mat-error>
          </mat-form-field>
        </div>
        <div
          fxLayout.gt-sm="row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto"
        >
          <div fxLayout.gt-sm="row" fxLayout="column" class="form-widht">
            <mat-form-field class="form-mr">
              <input
                matInput
                placeholder="Por Cada:"
                formControlName="porCada"
              />
              <mat-icon matSuffix>attach_money</mat-icon>
              <mat-error *ngIf="form.controls['porCada'].hasError('pattern')">
                Solo puede tener números.
              </mat-error>
            </mat-form-field>
            <mat-form-field class="form-mr">
              <input
                matInput
                placeholder="Entrego:"
                formControlName="entrego"
              />
              <mat-icon matSuffix>attach_money</mat-icon>
              <mat-error *ngIf="form.controls['entrego'].hasError('pattern')">
                Solo puede tener números.
              </mat-error>
            </mat-form-field>
          </div>
          <mat-form-field fxFlex="50" class="pr-4">
            <input
              matInput
              placeholder="Horario Disponible (opcional)"
              formControlName="hoursAvailable"
            />
          </mat-form-field>
        </div>
        <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="1 0 auto">
          <mat-form-field
            class="example-full-width"
            appearance="standard"
            style="width: 100%"
          >
            <mat-label>Agrega un comentario</mat-label>
            <textarea
              matInput
              placeholder="Ex. It makes me feel..."
              formControlName="comment"
            ></textarea>
          </mat-form-field>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="5px"
      >
        <div>
          <button mat-icon-button color="warn" *ngIf="!isNewDelivery">
            <mat-icon
              class="mat-icon-danger"
              aria-label="Delete post"
              (click)="delete()"
              >delete</mat-icon
            >
          </button>
        </div>
        <div>
          <button mat-raised-button (click)="closeDialog()">Cerrar</button>
          <button
            mat-raised-button
            color="primary"
            (click)="save()"
            [disabled]="
              !form.valid || (!checkboxDays && !form.controls.departure.value)
            "
          >
            Guardar
          </button>
        </div>
      </mat-dialog-actions>
      <div *ngIf="isLoading" fxLayoutAlign="center" class="spinner">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </ng-template>
</div>
<div *ngIf="isLoading" fxLayoutAlign="center" class="spinner">
  <mat-spinner></mat-spinner>
</div>
