<div fxLayout="row" fxLayoutAlign="center center" class="login-style">
  <mat-card class="login-card mt-10vh" fxFlex="350px" fxFlex.lt-sm="90%">
    <mat-card-header>
      <mat-card-title fxLayoutAlign="center center"
        ><h3>Recuperar su contraseña</h3></mat-card-title
      >
      <mat-card-subtitle
        >Ingrese su dirección de correo electrónico a continuación y le
        enviaremos una contraseña temporal.</mat-card-subtitle
      >
    </mat-card-header>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="getTempPassword()">
      <mat-card-content class="auth-material-card-content">
        <p>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>Introduzca su correo electrónico</mat-label>
            <input
              matInput
              placeholder="Correo electrónico"
              formControlName="email"
            />
            <mat-icon matSuffix>mail_outline</mat-icon>
            <mat-error
              *ngIf="resetPasswordForm.controls['email'].errors?.required"
              >El correo es requerido.</mat-error
            >
            <mat-error *ngIf="resetPasswordForm.controls['email'].errors?.email"
              >Correo electrónico inválido.</mat-error
            >
          </mat-form-field>
        </p>
      </mat-card-content>
      <mat-card-actions class="auth-material-card-actions">
        <div fxLayout="row">
          <button
            mat-raised-button
            color="primary"
            [disabled]="resetPasswordForm.invalid"
            fxFlex="100%"
          >
            ENVIAR CORREO
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <h3 class="mat-subheading-2 mt-10px mb-10px login-font-color">
            Sabes tu contraseña?
          </h3>
        </div>
        <div fxLayout="row">
          <button
            mat-stroked-button
            [routerLink]="['/login']"
            fxFlex="100%"
            class="mb-10px"
          >
            INICIAR SESIÓN
          </button>
        </div>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
