<div
  *ngIf="mostrar === 0"
  fxLayout="row"
  fxLayoutAlign="center center"
  class="mt-10px"
>
  <mat-card class="mat-card-custom">
    <mat-card-header fxLayout="row">
      <mat-card-title class="detalles">Detalles Generales</mat-card-title>
      <div fxLayout="row" fxLayoutAlign="end center" class="width-100">
        <div style="margin-right: 30px">
          <b class="rating"
            ><button
              mat-raised-button
              color="primary"
              (click)="comprar()"
              [hidden]="visible"
            >
              Comprar
            </button></b
          >
        </div>
        <div>
          <button mat-icon-button color="primary" (click)="volver()">
            <mat-icon class="mat-icon-primary">arrow_back</mat-icon>
          </button>
        </div>
      </div>
    </mat-card-header>
    <div fxLayout="row" class="user-img-rating-section">
      <div
        fxLayout="row"
        fxFlex="100"
        fxFlex.gt-sm="50"
        class="justify-content-center"
        *ngIf="account?.profileImageVirtualPath === null"
      >
        <div mat-card-avatar class="example-header-image"></div>
      </div>

      <div
        fxLayout="row"
        fxFlex="100%"
        fxFlex.gt-sm="50%"
        style="justify-content: center"
        *ngIf="account?.profileImageVirtualPath !== null"
      >
        <img
          mat-card-avatar
          src="{{ baseUrl }}{{ account.profileImageVirtualPath }}?{{ time }}"
        />
      </div>
    </div>
    <div fxLayout="row">
      <div fxLayout="column" fxFlex.gt-sm="50" fxFlex="100" class="mb-10px">
        <div fxLayout="row" class="justify-content-center">
          <h2 class="mb-5px">{{ this.account?.displayName }}</h2>
        </div>
        <div
          fxLayout="row"
          *ngIf="delivery?.rating !== null"
          class="rating justify-content-center"
        >
          <div>
            <ngx-material-rating
              [style.color]="'orange'"
              [readonly]="'true'"
              [(ngModel)]="delivery?.rating.valoracion"
              [max]="5"
            >
            </ngx-material-rating>
          </div>
          <span class="font"> {{ delivery?.rating.valoracion }}/{{ 5 }} </span>
        </div>
        <div fxLayout="row" class="justify-content-center">
          <button mat-button color="primary" (click)="transferirData()">
            {{ getRatingButtonText() }}
          </button>
        </div>
      </div>
    </div>
    <mat-card-content>
      <form
        fxLayout="column"
        fxLayoutAlign="start"
        fxFlex="1 0 auto"
        [formGroup]="accountForm"
      >
        <h2>Informacion sobre el viaje</h2>
        <hr />
        <div
          fxLayout.gt-md="row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto"
        >
          <mat-form-field appearance="outline" class="pr-4 width-100">
            <mat-label>Ciudad de Salida</mat-label>
            <input matInput readonly="true" formControlName="from" />
            <mat-icon matSuffix>flight_takeoff</mat-icon>
          </mat-form-field>
          <mat-form-field
            fxFlex="50"
            appearance="outline"
            class="pl-4 width-100"
          >
            <mat-label>Ciudad Destino</mat-label>
            <input matInput readonly="true" formControlName="to" />
            <mat-icon matSuffix>flight_land</mat-icon>
          </mat-form-field>
        </div>
        <div
          fxLayout.gt-md="row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto"
          [hidden]="visible"
        >
          <mat-form-field
            fxFlex="50"
            appearance="outline"
            class="pr-4 width-100"
          >
            <mat-label
              >Dirección de entrega de el paquete para enviar</mat-label
            >
            <input
              matInput
              placeholder="Dirección de entrega de el paquete para enviar"
              formControlName="parcelDropoffAddress"
              readonly="true"
            />
            <mat-icon matSuffix>location_on</mat-icon>
          </mat-form-field>
          <mat-form-field
            fxFlex="50"
            appearance="outline"
            class="pl-4 width-100"
          >
            <mat-label
              >Dirección de entrega del paquete en el destino</mat-label
            >
            <input
              matInput
              placeholder="Dirección de entrega del paquete en el destino"
              formControlName="parcelPickupAddress"
              readonly="true"
            />
            <mat-icon matSuffix>location_on</mat-icon>
          </mat-form-field>
        </div>
        <div
          fxLayout.gt-md="row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto"
        >
          <mat-form-field
            fxFlex="50"
            appearance="outline"
            class="pr-4 width-100"
          >
            <mat-label>Salida</mat-label>
            <input
              *ngIf="delivery?.departure !== null"
              matInput
              readonly="true"
              [value]="delivery.departure | date"
            />
            <input
              *ngIf="delivery?.departure === null"
              matInput
              readonly="true"
              [value]="'Todos los Dias'"
            />
            <mat-icon matSuffix>flight_takeoff</mat-icon>
          </mat-form-field>
        </div>

        <h2>Informacion sobre los envios</h2>
        <hr />
        <div
          fxLayout.gt-md="row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto"
        >
          <mat-form-field
            fxFlex="50"
            appearance="outline"
            class="pl-4 width-100"
          >
            <mat-label>Libras Disponibles</mat-label>
            <input
              matInput
              formControlName="librasDisponibles"
              readonly="true"
            />
            <mat-icon matSuffix>scale</mat-icon>
          </mat-form-field>
          <mat-form-field
            fxFlex="50"
            appearance="outline"
            class="pl-4 width-100"
          >
            <mat-label>Precio/Libra</mat-label>
            <input matInput formControlName="price" readonly="true" />
            <mat-icon matSuffix>attach_money</mat-icon>
          </mat-form-field>
        </div>
        <div
          fxLayout.gt-md="row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto"
        >
          <mat-form-field
            fxFlex="25"
            appearance="outline"
            class="pl-4 width-100"
          >
            <mat-label>Por cada</mat-label>
            <input
              matInput
              placeholder="Por cada"
              formControlName="porCada"
              readonly="true"
            />
            <mat-icon matSuffix>attach_money</mat-icon>
          </mat-form-field>
          <mat-form-field
            fxFlex="25"
            appearance="outline"
            class="pl-4 width-100"
          >
            <mat-label>Entrego</mat-label>
            <input
              matInput
              placeholder="Entrego"
              formControlName="entrego"
              readonly="true"
            />
            <mat-icon matSuffix>attach_money</mat-icon>
          </mat-form-field>
          <mat-form-field
            fxFlex="25"
            appearance="outline"
            class="pl-4 width-100"
          >
            <mat-label>Telefonos a llevar</mat-label>
            <input
              matInput
              placeholder="Telefonos a llevar"
              formControlName="telefonosALlevar"
              readonly="true"
            />
            <mat-icon matSuffix>smartphone</mat-icon>
          </mat-form-field>
          <mat-form-field
            fxFlex="25"
            appearance="outline"
            class="pl-4 width-100"
          >
            <mat-label>Precio por telefono</mat-label>
            <input
              matInput
              placeholder="Precio por telefono"
              formControlName="precioPorTelefono"
              readonly="true"
            />
            <mat-icon matSuffix>attach_money</mat-icon>
          </mat-form-field>
        </div>
        <h2>Contacto</h2>
        <hr class="width-100" />
        <div
          fxLayout.gt-md="row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto"
        >
          <mat-form-field
            fxFlex="50"
            appearance="outline"
            class="pr-4 width-100"
            [hidden]="visible"
          >
            <mat-label>Dinero mínimo por entrega</mat-label>
            <input
              matInput
              placeholder="Dinero mínimo por entrega"
              formControlName="entregaMinima"
              readonly="true"
            />
            <mat-icon matSuffix>attach_money</mat-icon>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="pl-4 width-100"
            [hidden]="visible"
          >
            <mat-label>Dinero máximo a llevar</mat-label>
            <input
              matInput
              placeholder="Dinero máximo a llevar"
              formControlName="dineroDisponible"
              readonly="true"
            />
            <mat-icon matSuffix>attach_money</mat-icon>
          </mat-form-field>
        </div>
        <div
          fxLayout.gt-md="row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto"
        >
          <mat-form-field
            fxFlex="50"
            appearance="outline"
            class="pr-4 width-100"
          >
            <mat-label>Numero de Telefono</mat-label>
            <input
              matInput
              placeholder="Phone Number"
              formControlName="phoneNumber"
              readonly="true"
            />
            <mat-icon matSuffix>phone</mat-icon>
          </mat-form-field>
          <mat-form-field
            fxFlex="50"
            appearance="outline"
            class="pl-4 width-100"
          >
            <mat-label>Correo electronico</mat-label>
            <input
              matInput
              placeholder="Email"
              formControlName="email"
              readonly="true"
            />
            <mat-icon matSuffix>mail</mat-icon>
          </mat-form-field>
        </div>
        <div
          fxLayout.gt-md="row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto"
        >
          <mat-form-field
            fxFlex="50"
            appearance="outline"
            class="pr-4 width-100"
            [hidden]="visible"
          >
            <mat-label>Pagina Web</mat-label>
            <input
              matInput
              placeholder="Website (optional)"
              formControlName="website"
              readonly="true"
            />
            <mat-icon matSuffix>web_asset</mat-icon>
          </mat-form-field>
          <mat-form-field
            fxFlex="50"
            appearance="outline"
            class="pl-4 width-100"
          >
            <mat-label>Horario Disponible</mat-label>
            <input
              matInput
              placeholder="Horario Disponible"
              formControlName="hoursAvailable"
              readonly="true"
            />
            <mat-icon matSuffix>schedule</mat-icon>
          </mat-form-field>
        </div>

        <h2>Detalles adicionales</h2>
        <hr />
        <div
          fxLayout.gt-md="row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto"
          style="margin-bottom: 15px"
        >
          <mat-form-field appearance="outline" class="width-100">
            <mat-label>Detalles</mat-label>
            <textarea
              rows="5"
              matInput
              placeholder="Ex. I have many years delivering..."
              formControlName="serviceDescription"
              readonly="true"
            ></textarea>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
<div *ngIf="mostrar === 1">
  <app-search-courier></app-search-courier>
</div>
<div *ngIf="mostrar === 2">
  <app-review
    [reviewSeleccionado]="reviews"
    [deliveryDetails]="delivery"
  ></app-review>
</div>
<div
  *ngIf="isLoading"
  fxLayoutAlign="center"
  style="position: absolute; top: 40%; right: 35%; left: 35%"
>
  <mat-spinner></mat-spinner>
</div>
