<div fxLayout="column">
  <div fxFlex="100%">
    <app-nav></app-nav>
  </div>
  <div fxLayout="100%" class="content">
    <div fxFlex.xs="1%" fxFlex.sm="1%" fxFlex.md="5%" fxFlex.lg="18%" fxFlex.xl="20%"></div>
    <div fxFlex><router-outlet></router-outlet></div>
    <div fxFlex.xs="1%" fxFlex.sm="1%" fxFlex.md="5%" fxFlex.lg="18%" fxFlex.xl="20%"></div>
  </div>
  <div fxFlex="80%">    
      <app-footer></app-footer>        
  </div>
</div>


