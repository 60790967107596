import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Reviews } from '../models/Reviews';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  private baseUrl = environment.apiUrl;
  private controllerName = 'reviews';

  constructor(private http: HttpClient) {}

  searchReviews(    
    userId: number
  ): Observable<any> {
    return this.http
    .get(this.baseUrl + this.controllerName + '/getReviews/' + `${userId}`); 
  }

  createReviews(    
    model: Reviews
  ): Observable<any> {
    return this.http
    .post(this.baseUrl + this.controllerName + '/createReview/' , model); 
  }

  updateReviews(model: Reviews): Observable<any> {
    return this.http.put(this.baseUrl + this.controllerName, model);
  }
}
