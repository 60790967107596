import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delivery-codeconfirmation-dialog',
  templateUrl: './delivery-codeconfirmation-dialog.component.html',
  styleUrls: ['./delivery-codeconfirmation-dialog.component.css'],
})
export class DeliveryCodeconfirmationDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DeliveryCodeconfirmationDialogComponent>
  ) {}
  codigo: string;
  monto: number;
  dateNow = new Date();
  ngOnInit(): void {
    this.codigo = this.data.confirmationCode;
    this.monto = this.data.moneySent;
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
