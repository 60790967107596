import { Injectable } from '@angular/core';
import { Delivery } from '../models/Delivery';

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {
  deliveryData: Delivery;
  constructor() { }

  public get getDelivery() {
    return this.deliveryData;
  }

  public set setDelivery(deliveryData: Delivery) {
    this.deliveryData = deliveryData;
  }
}
