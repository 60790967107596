import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Account } from '../models/Account';
import { AccountDto } from '../models/AccountDto';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  create(account: AccountDto): Observable<any> {
    return this.http.post(this.baseUrl + 'account', account);
  }

  updateAccount(account: AccountDto): Observable<any> {
    return this.http.put(this.baseUrl + 'account', account);
  }

  getAccountByUserId(userId: number): Observable<Account> {
    return this.http.get<Account>(
      this.baseUrl + 'account/login/' + `${userId}`
    );
  }

  getAccountByUserName(userName: string): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + 'account/getName/' + `${userName}`
    );
  }

  getAccountByDisplayName(displayName: string): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + 'account/getDisplayName/' + `${displayName}`
    );
  }
}
