<mat-dialog-content>
  <div style="width: 100%" fxLayout="row wrap" fxLayoutAlign="flex-start">
    <div>
      <div>
        <p style="font-size: 16px; margin-bottom: 5px">
          <span style="font-size: 14px; font-weight: bold"
            ><h2>Los códigos se procesaron exitosamente.</h2></span
          >
        </p>
      </div>
    </div>
    <mat-card
      fxLayoutAlign="center"
      fxLayout="column"
      style="cursor: pointer; margin: 5px; width: 100%"
    >
      <mat-card-content style="margin-bottom: 0px; margin-top: 8px">
        <table>
          <tr>
            <td>Codigo de confirmación :</td>
            <td>
              <b>{{ codigo }}</b>
            </td>
          </tr>
          <tr>
            <td>Monto transferido:</td>
            <td>
              <b>{{ monto }} usd</b>
            </td>
          </tr>
          <tr>
            <td>Fecha:</td>
            <td>
              <b>{{ dateNow | date: "MM-dd-yyyy" }}</b>
            </td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" (click)="onConfirmClick()">
    Aceptar
  </button>
</mat-dialog-actions>
