<div>
  <mat-toolbar color="primary" fxLayoutAlign="center center">
    <div fxFlex="20%"></div>
    <div fxLayoutAlign="start center">
      <div fxLayout="column" fxLayout.gt-sm="row">
        <a [routerLink]="['/terminos', 1]" mat-button>
          <span class="login">Términos y condiciones</span>
        </a>
        <a [routerLink]="['/terminos', 2]" mat-button>
          <span class="login">Política de cancelación </span>
        </a>
      </div>
      <div fxLayout="column" fxLayout.gt-sm="row">
        <a [routerLink]="['/terminos', 3]" mat-button>
          <span class="login">Política de privacidad</span>
        </a>
        <a [routerLink]="['/terminos', 4]" mat-button>
          <span class="login">Contactenos</span>
        </a>
      </div>
    </div>
    <div fxFlex="20%"></div>
  </mat-toolbar>
</div>
