import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { RegisterComponent } from './auth/register/register.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material-module';
import { JwtInterceptor } from './helpers/jwt-interceptor';
import { environment } from 'src/environments/environment';
import { SearchCourierComponent } from './main/search-courier/search-courier.component';
import { ToastrModule } from 'ngx-toastr';
import { ErrorInterceptor } from './helpers/error-interceptor';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ManageAccountComponent } from './main/manage-account/manage-account.component';
import { ManageDeliveriesComponent } from './main/delivery/manage-deliveries/manage-deliveries.component';
import { DeliveryRouteComponent } from './main/delivery/delivery-route/delivery-route.component';
import { AddOrEditDeliveryDialogComponent } from './main/delivery/add-or-edit-delivery/add-or-edit-delivery-dialog.component';
import { ConfirmationDialogComponent } from './main/confirmation-dialog/confirmation-dialog.component';
import { PasswordRecoveryComponent } from './auth/password-recovery/password-recovery.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CourierDetailsComponent } from './main/search-courier/courier-details/courier-details.component';
import { NgxMaterialRatingModule } from 'ngx-material-rating';
import { CourierDetailsService } from './services/courier-details.service';
import { ReviewComponent } from './main/search-courier/courier-details/review/review.component';
import { AddReviewComponent } from './main/search-courier/courier-details/add-review/add-review.component';
import { FooterComponent } from './footer/footer.component';
import { TerminosComponent } from './footer/terminos/terminos.component';
import { BuyDialogComponent } from './main/search-courier/courier-details/buy-dialog/buy-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { ChargingdeliveryBycodeComponent } from './main/delivery/chargingdelivery-bycode/chargingdelivery-bycode.component';
import { DeliveryCodeconfirmationDialogComponent } from './main/delivery/delivery-codeconfirmation-dialog/delivery-codeconfirmation-dialog.component';
import { DeliveryTotalAmountComponent } from './main/delivery/delivery-total-amount/delivery-total-amount.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    PasswordRecoveryComponent,
    SearchCourierComponent,
    ManageAccountComponent,
    ManageDeliveriesComponent,
    PageNotFoundComponent,
    DeliveryRouteComponent,
    AddOrEditDeliveryDialogComponent,
    ConfirmationDialogComponent,
    CourierDetailsComponent,
    ReviewComponent,
    AddReviewComponent,
    FooterComponent,
    TerminosComponent,
    BuyDialogComponent,
    ChargingdeliveryBycodeComponent,
    DeliveryCodeconfirmationDialogComponent,
    DeliveryTotalAmountComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    GooglePlaceModule,
    NgxMaterialRatingModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatCheckboxModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddOrEditDeliveryDialogComponent,
    ConfirmationDialogComponent,
  ],
})
export class AppModule {}
