import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Delivery } from 'src/app/models/Delivery';
import { DeliverySale } from 'src/app/models/DeliverySale';
import { DeliverySaleDto } from 'src/app/models/DeliverySaleDto';
import { DeliverySaleService } from 'src/app/services/delivery-sale.service';
import { DeliveryService } from 'src/app/services/delivery.service';

@Component({
  selector: 'app-buy-dialog',
  templateUrl: './buy-dialog.component.html',
  styleUrls: ['./buy-dialog.component.css'],
})
export class BuyDialogComponent implements OnInit {
  isLoading = true;
  public form: FormGroup;
  checkboxDinero: boolean;
  checkboxLibras: boolean;
  checkboxTelefonos: boolean;
  costoDinero: number = 0;
  costoLibras: number = 0;
  costoTelefonos: number = 0;
  diferencia: number;
  porCiento: number;
  ganancia: number;
  deliverySale: DeliverySale;
  productPurchased: string[] = [];
  deliverySaleDto: DeliverySaleDto;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<BuyDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: Delivery,
    private toastr: ToastrService,
    private deliverySaleService: DeliverySaleService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      correo: [null, [Validators.required, Validators.email]],
      dinero: [null],
      libras: [null],
      telefonos: [null],
    });
    this.isLoading = false;
  }

  pagar() {
    if (!this.checkboxDinero) {
      this.form.controls.dinero.setValue(null);
    }
    if (!this.checkboxLibras) {
      this.form.controls.libras.setValue(null);
    }
    if (!this.checkboxTelefonos) {
      this.form.controls.telefonos.setValue(null);
    }
    if (
      this.form.controls.dinero.value !== null ||
      this.form.controls.libras.value !== null ||
      this.form.controls.telefonos.value !== null
    ) {
      if (this.checkboxDinero) {
        this.productPurchased.push('dinero');
      }
      if (this.checkboxLibras) {
        this.productPurchased.push('libras');
      }
      if (this.checkboxTelefonos) {
        this.productPurchased.push('telefonos');
      }

      this.data.moneySent = this.form.controls.dinero.value;
      this.data.poundsBought = this.form.controls.libras.value;
      this.data.phoneBought = this.form.controls.telefonos.value;
      this.data.buyerEmail = this.form.controls.correo.value;
      this.data.totalPaid =
        this.costoTelefonos + this.costoDinero + this.costoLibras;
      this.data.productPurshased = this.productPurchased;
      this.data.deliveryUserMoney = 20;

      const parametro: DeliverySaleDto = {
        deliveryDto: this.data,
      };
      this.deliverySaleService.createDelivery(parametro).subscribe(
        (data) => {
          this.toastr.success('La compra se ha efectuado satisfactoriamente');
          this.closeDialog();
        },
        (error: any) => {
          this.toastr.error('Ha ocurrido un error en la operacion');
        }
      );
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  verificarMontos(monto: number) {
    this.costoDinero = parseFloat(this.gananciaDinero(monto).toFixed(2));
    if (monto > this.data.dineroDisponible) {
      this.costoDinero = 0;
      this.toastr.error('El monto no puede exceder el dinero disponible');
      this.form.controls['dinero'].setErrors(Validators.required);
    }
    if (monto < this.data.entregaMinima) {
      this.costoDinero = 0;
      this.toastr.error(
        'El monto no puede ser menor que la cantidad minima a llevar'
      );
      this.form.controls['dinero'].setErrors(Validators.required);
    }
  }

  verificarLibras(libras: number) {
    this.costoLibras = parseFloat(this.gananciaLibras(libras).toFixed(2));
    if (libras > this.data.librasDisponibles) {
      this.costoLibras = 0;
      this.toastr.error(
        'La cantidad de libras no puede exceder las libras disponibles'
      );
      this.form.controls['libras'].setErrors(Validators.required);
    }
  }

  verificarTelefonos(telefonos: number) {
    this.costoTelefonos = parseFloat(
      this.gananciaTelefonos(telefonos).toFixed(2)
    );
    if (telefonos > this.data.telefonosALlevar) {
      this.costoTelefonos = 0;
      this.toastr.error(
        'La cantidad de telefonos no puede exceder los disponibles'
      );
      this.form.controls['telefonos'].setErrors(Validators.required);
    }
  }

  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  gananciaDinero(monto) {
    this.diferencia = this.data.porCada - this.data.entrego;
    this.porCiento = (this.diferencia / this.data.entrego) * 100;
    this.ganancia = (this.porCiento * monto) / 100;
    return parseFloat(monto) + this.ganancia;
  }

  gananciaLibras(libras) {
    return this.data.price * libras;
  }

  gananciaTelefonos(telefonos) {
    return this.data.precioPorTelefono * telefonos;
  }
}
