import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Account } from 'src/app/models/Account';
import { AccountDto } from 'src/app/models/AccountDto';
import { AccountService } from 'src/app/services/account.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss'],
})
export class ManageAccountComponent implements OnInit {
  isLoading = true;
  account: Account;
  passActualHide = true;
  passNuevoHide = true;
  privateForm: FormGroup;
  validpass = false;
  accountForm = this.formBuilder.group({
    displayName: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
    ]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phoneNumber: new FormControl(
      Validators.required,
      Validators.pattern('^[0-9-]*$')
    ),
    website: new FormControl('', null),
    serviceDescription: new FormControl('', null),
    zelleInformation: new FormControl('', null),
  });
  imagenIdentificacion: string;
  cargaImagen: boolean;
  imagenFotoPerfil: string;
  validDisplayName: boolean = true;
  visible = environment.hidden;
  baseUrl: string;
  time: number;
  base: boolean = false;
  displayName: string;

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private toastr: ToastrService,
    private authService: AuthService,
    private sanitizer: DomSanitizer
  ) {
    this.baseUrl = environment.apiUrl.replace('api/', '');
    this.time = new Date().getUTCMilliseconds();
  }

  ngOnInit() {
    const currentUser = this.authService.currentUserValue;
    this.accountService.getAccountByUserId(currentUser.id).subscribe(
      (accountData: Account) => {
        this.account = accountData;
        this.displayName = this.account.displayName;
        this.imagenIdentificacion = this.account.imagenBase64;
        this.imagenFotoPerfil = this.account.profileImageVirtualPath;
        this.accountForm.patchValue(this.account);
        if (this.account.profileImageVirtualPath !== null) {
          this.cargaImagen = true;
        }
      },
      (error) => {
        this.account = null;
        this.accountForm.reset();
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
    this.privateForm = this.formBuilder.group(
      {
        user: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        currentPass: new FormControl(null),
        newPass: new FormControl(null),
        confirmPass: new FormControl(null),
      },
      { validator: this.MustMatch('newPass', 'confirmPass') }
    );
    this.privateForm.controls.user.setValue(currentUser.username);
    this.privateForm.controls.email.setValue(currentUser.email);
  }

  onSaveChanges(): void {
    if (this.accountForm.invalid) {
      return;
    }
    this.isLoading = true;
    const account: AccountDto = {
      account: this.accountForm.value,
      fotoPerfilBase64: this.imagenFotoPerfil,
    };
    account.account.userId = this.authService.currentUserValue.id;
    account.account.imagenBase64 = this.imagenIdentificacion;
    if (this.account === null) {
      this.accountService.create(account).subscribe(
        (data: Account) => {
          this.account = data;
          const user = this.authService.currentUserValue;
          user.isProfileSetup = true;
          this.authService.updateLocalStorageUser(user);
          this.toastr.success(
            'La información de la cuenta se ha guardado satisfactoriamente.'
          );
        },
        (error) => {
          this.isLoading = false;
          this.toastr.error(error.message);
        },
        () => (this.isLoading = false)
      );
      return;
    } else {
      if (!this.base) {
        account.fotoPerfilBase64 = null;
        account.account.directorioFotoPerfil =
          this.account.directorioFotoPerfil;
        account.account.profileImageVirtualPath =
          this.account.profileImageVirtualPath;
      }
      account.account.accountId = this.account.accountId;
      this.accountService.updateAccount(account).subscribe(
        () => {
          this.toastr.success(
            'La información de la cuenta se ha guardado satisfactoriamente.'
          );
        },
        (error) => {
          this.isLoading = false;
          this.toastr.error(error.message);
        },
        () => (this.isLoading = false)
      );
    }
    this.displayName = account.account.displayName;
  }

  onSaveChangesPrivate() {
    this.authService
      .updateUser(
        this.authService.currentUserValue.id,
        this.privateForm.controls['user'].value,
        this.privateForm.controls['email'].value,
        this.privateForm.controls['newPass'].value
      )
      .subscribe((response) => {
        this.toastr.success('Se actualizó correctamente el usuario.');
        this.privateForm.controls['currentPass'].reset();
        this.privateForm.controls['newPass'].reset();
        this.privateForm.controls['confirmPass'].reset();
        const currentUser = this.authService.currentUserValue;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.privateForm.controls.user.updateValueAndValidity();
        this.privateForm.controls.email.updateValueAndValidity();
      });
    (error) => {
      this.isLoading = false;
      this.toastr.error(error.message);
    };
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  validPass(event: any) {
    if (
      this.privateForm.controls['newPass'].value ===
      this.privateForm.controls['confirmPass'].value
    ) {
      this.validpass = true;
    } else {
      this.validpass = false;
    }
  }

  verificarPass(pass: string) {
    if (pass != '') {
      this.authService
        .verificarPass(this.authService.currentUserValue.email, pass)
        .subscribe((response) => {
          if (!response) {
            this.privateForm.controls['currentPass'].setErrors(
              Validators.required
            );
            this.toastr.error('La contraseña actual incorrecta');
          }
        });
    } else this.privateForm.controls['currentPass'].updateValueAndValidity();
  }

  uploadFileEvt(event): any {
    const imagen = event.target.files[0];
    this.extraerBase64(imagen).then((file: any) => {
      this.imagenIdentificacion = file.base;
    });
  }

  uploadProfilePicture(event): any {
    this.cargaImagen = false;
    const imagen = event.target.files[0];
    this.extraerBase64(imagen).then((file: any) => {
      this.imagenFotoPerfil = file.base;
      this.base = true;
    });
  }

  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event);
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
        const reader = new FileReader();
        reader.readAsDataURL($event);
        reader.onload = () => {
          resolve({
            base: reader.result,
          });
        };
        reader.onerror = (error) => {
          resolve({
            base: null,
          });
        };
      } catch (e) {
        return null;
      }
    });

  verificarDisplayName(event: any) {
    if (
      this.accountForm.controls['displayName'].value.length > 3 &&
      this.displayName != this.accountForm.controls['displayName'].value
    ) {
      this.accountService
        .getAccountByDisplayName(this.accountForm.controls.displayName.value)
        .subscribe((data) => {
          if (data.length !== 0) {
            this.validDisplayName = false;
            this.accountForm.controls['displayName'].setErrors(
              Validators.required
            );
          }
        });
    }
  }
}
