import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Delivery } from 'src/app/models/Delivery';
import {
  MatDialogRef,
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import { AddOrEditDeliveryDialogComponent } from '../add-or-edit-delivery/add-or-edit-delivery-dialog.component';
import { DeliveryService } from 'src/app/services/delivery.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { UpdateIsActiveDelivery } from 'src/app/models/updateIsActiveDelivery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delivery-route',
  templateUrl: './delivery-route.component.html',
  styleUrls: ['./delivery-route.component.scss'],
})
export class DeliveryRouteComponent implements OnInit {
  @Input() route: Delivery;
  @Input() isUserView: boolean;
  @Output() deliveryRouteDeleted = new EventEmitter<Delivery>();
  mensaje: string;
  isActive: boolean;
  baseUrl: string;
  time: number;

  constructor(
    private dialog: MatDialog,
    private deliveryService: DeliveryService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<AddOrEditDeliveryDialogComponent>
  ) {
    this.baseUrl = environment.apiUrl.replace('api/', '');
    this.time = new Date().getUTCMilliseconds();
  }

  ngOnInit() {
    if (this.route.isActive === true) {
      this.mensaje = 'Ocultar';
    } else {
      this.mensaje = 'Mostrar';
    }
  }

  edit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.route;

    const dialogRef = this.dialog.open(
      AddOrEditDeliveryDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((delivery: Delivery) => {
      if (delivery === null) {
        this.deliveryRouteDeleted.emit(this.route);
        return;
      }
      this.route = delivery;
    });
  }

  delete() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Está seguro de que desea eliminar esta ruta de entrega?',
        buttonText: {
          ok: 'Ok',
          cancel: 'Cancel',
        },
        alert: 'Alert',
      },
    });
    dialogRef.afterClosed().subscribe((delt) => {
      if (delt) {
        this.deliveryService.deleteDelivery(this.route.deliveryId).subscribe(
          () => {
            this.deliveryRouteDeleted.emit(this.route);
            this.toastr.success('Ruta de entrega eliminada con éxito.');
          },
          (error) => {
            this.toastr.error(error.message);
          }
        );
      }
    });
  }

  post() {
    if (this.mensaje === 'Mostrar') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }

    const parametro: UpdateIsActiveDelivery = {
      deliveryId: this.route.deliveryId,
      isActive: this.isActive,
    };

    this.deliveryService.updateIsActive(parametro).subscribe(() => {
      if (parametro.isActive === false) {
        this.mensaje = 'Mostrar';
      } else {
        this.mensaje = 'Ocultar';
      }
    });
  }
}
