<div fxFlex="100">
  <div fxLayout="row">
    <mat-label class="valoracion">Agrega una valoración</mat-label>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" class="start">
    <ngx-material-rating
      [style.color]="'orange'"
      [(ngModel)]="rating[0].value"
      [max]="5"
    >
    </ngx-material-rating>
    <div>
      <span class="rating"> Rating {{ rating[0].value }} of 5 </span>
    </div>
  </div>
  <mat-dialog-content
    fxLayout="column"
    fxLayoutAlign="center start"
    [formGroup]="form"
  >
    <mat-form-field
      class="example-full-width"
      appearance="standard"
      style="width: 100%"
    >
      <textarea
        rows="5"
        matInput
        placeholder=""
        formControlName="comentario"
      ></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="5px"
  >
    <button mat-raised-button (click)="closeDialog()">Close</button>
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      [disabled]="!form.valid || rating[0].value === 0"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
