import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DeliverySaleService } from 'src/app/services/delivery-sale.service';
import { DeliverySale } from 'src/app/models/DeliverySale';

@Component({
  selector: 'app-delivery-total-amount',
  templateUrl: './delivery-total-amount.component.html',
  styleUrls: ['./delivery-total-amount.component.css'],
})
export class DeliveryTotalAmountComponent implements OnInit {
  @ViewChild('paginatorDelivery') paginatorDelivery: MatPaginator;
  //@ViewChild(MatSort) sort: MatSort;
  salePayment: DeliverySale[] = [];
  sortedData: DeliverySale[];

  displayedColumnsDelivery: string[] = ['codigo', 'producto', 'monto'];
  dataSourceDeliveryPayments: MatTableDataSource<any>;
  constructor(
    private deliverySaleService: DeliverySaleService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.deliverySaleService
      .getDeliverySalePayment(this.authService.currentUserId)
      .subscribe((data) => {
        this.dataSourceDeliveryPayments = new MatTableDataSource<any>(data);
        this.dataSourceDeliveryPayments.data = data;
        this.salePayment = data;
      });
  }

  volver() {
    this.router.navigate(['/manage-services']);
  }

  sortData(sort: Sort) {
    const data = this.salePayment.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSourceDeliveryPayments.data = data;
      return;
    }
    this.dataSourceDeliveryPayments.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'deliverySaleCode':
          return this.compare(a.deliverySaleCode, b.deliverySaleCode, isAsc);
        // case 'productPurshased':
        //   return this.compare(a.productPurshased, b.productPurshased, isAsc);
        case 'deliveryUserMoney':
          return this.compare(a.deliveryUserMoney, b.deliveryUserMoney, isAsc);
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
