<h2 mat-dialog-title>Escoja la opción de envío:</h2>
<div fxLayout="row">
  <div>
    <p style="font-size: 15px">
      <mat-checkbox #checkDinero (change)="checkboxDinero = checkDinero.checked"
        >Dinero</mat-checkbox
      >
    </p>
  </div>
  <div>
    <p style="font-size: 15px; margin-left: 15px">
      <mat-checkbox #checkLibras (change)="checkboxLibras = checkLibras.checked"
        >Libras</mat-checkbox
      >
    </p>
  </div>
  <div>
    <p style="font-size: 15px; margin-left: 15px">
      <mat-checkbox
        #checkTelefonos
        (change)="checkboxTelefonos = checkTelefonos.checked"
        >Telefonos</mat-checkbox
      >
    </p>
  </div>
</div>
<mat-divider style="margin-bottom: 20px"></mat-divider>
<mat-dialog-content
  fxLayout="column"
  fxLayoutAlign="center start"
  [formGroup]="form"
>
  <div
    *ngIf="checkboxDinero"
    fxLayout="row"
    fxLayoutAlign="space-between stretch"
  >
    <mat-form-field>
      <input
        #dinero
        matInput
        placeholder="Dinero a enviar"
        formControlName="dinero"
        (focusout)="verificarMontos(dinero.value)"
        (keypress)="keyPressNumbers($event)"
      />
      <mat-error *ngIf="form.controls['dinero'].errors?.required">
        El dinero es requerido.
      </mat-error>
    </mat-form-field>
    <div fxLayout="row" style="margin-left: 15px; white-space: nowrap">
      <h3 style="margin-top: 10px">Costo del envío:</h3>
      <b style="margin-top: 13px; margin-left: 5px">${{ costoDinero }}</b>
    </div>
  </div>
  <div
    *ngIf="checkboxLibras"
    fxLayout="row"
    fxLayoutAlign="space-between stretch"
  >
    <mat-form-field>
      <input
        matInput
        #libras
        placeholder="Libras a enviar"
        formControlName="libras"
        (focusout)="verificarLibras(libras.value)"
        (keypress)="keyPressNumbers($event)"
      />
      <mat-error *ngIf="form.controls['libras'].errors?.required">
        Las libras son requeridas.
      </mat-error>
    </mat-form-field>
    <div fxLayout="row" style="margin-left: 15px; white-space: nowrap">
      <h3 style="margin-top: 10px">Costo del envío:</h3>
      <b style="margin-top: 13px; margin-left: 5px">${{ costoLibras }}</b>
    </div>
  </div>
  <div
    *ngIf="checkboxTelefonos"
    fxLayout="row"
    fxLayoutAlign="space-between stretch"
  >
    <mat-form-field>
      <input
        matInput
        #telefonos
        placeholder="Telefonos a enviar"
        formControlName="telefonos"
        (focusout)="verificarTelefonos(telefonos.value)"
        (keypress)="keyPressNumbers($event)"
      />
      <mat-error *ngIf="form.controls['telefonos'].errors?.required">
        Los telefonos son requeridos.
      </mat-error>
    </mat-form-field>
    <div fxLayout="row" style="margin-left: 15px; white-space: nowrap">
      <h3 style="margin-top: 10px">Costo del envío:</h3>
      <b style="margin-top: 13px; margin-left: 5px">${{ costoTelefonos }}</b>
    </div>
  </div>
  <div fxLayoutAlign="space-between stretch">
    <mat-form-field>
      <input
        matInput
        placeholder="Introduzca su email para el recibo"
        formControlName="correo"
      />
      <mat-error *ngIf="form.controls['correo'].errors?.required">
        El correo es requerido.
      </mat-error>
      <mat-error *ngIf="form.controls['correo'].errors?.email">
        El correo es invalido.</mat-error
      >
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="5px"
>
  <div>
    <button mat-raised-button (click)="closeDialog()">Cancelar</button>
    <button
      mat-raised-button
      color="primary"
      (click)="pagar()"
      [disabled]="
        !form.valid ||
        (!checkboxDinero && !checkboxLibras && !checkboxTelefonos)
      "
    >
      Pagar
    </button>
  </div>
</mat-dialog-actions>
<div
  *ngIf="isLoading"
  fxLayoutAlign="center"
  style="position: absolute; top: 40%; right: 35%; left: 35%"
>
  <mat-spinner></mat-spinner>
</div>
