import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'src/app/models/User';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isLoading = true;
  passWordHide = true;
  visible = environment.hidden;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private route: Router,
    private toastr: ToastrService
  ) {
    const userValue = this.auth.currentUserValue;
    if (userValue) {
      route.navigate(['/search-courier']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [, Validators.required]],
    });
    this.isLoading = false;
  }

  get f() {
    return this.loginForm.controls;
  }
  onSubmit() {
    this.isLoading = true;
    if (this.loginForm.valid) {
      this.auth.login(this.f.email.value, this.f.password.value).subscribe(
        (data: User) => {
          if (data.isPasswordRecovery) {
            this.route.navigate(['/password-recovery']);
          } else {
            this.route.navigate(['/search-courier']);
          }
          this.toastr.success('Bienvenido: ' + data.username + '!');
        },
        (error) => {
          this.isLoading = false;
          this.toastr.error(
            'Su contraseña o email son incorrectos. Por favor intente de nuevo.'
          );
        },
        () => {
          this.isLoading = false;
        }
      );
    }
  }
}
