import { Component, OnInit } from '@angular/core';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  firstName = '';

  title = 'Nile-View';
  constructor(private login: LoginService) {}
  ngOnInit(): void {}

  getData() {
    this.login.getLogin().subscribe((data: any) => {
      this.firstName = data.firstName;
    });
  }
}
