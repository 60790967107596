import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Account } from 'src/app/models/Account';
import { Delivery } from 'src/app/models/Delivery';
import { Reviews } from 'src/app/models/Reviews';
import { AccountService } from 'src/app/services/account.service';
import { DataServiceService } from 'src/app/services/data-service.service';

import { ReviewsService } from 'src/app/services/reviews.service';
import { environment } from 'src/environments/environment';
import { BuyDialogComponent } from './buy-dialog/buy-dialog.component';

@Component({
  selector: 'app-courier-details',
  templateUrl: './courier-details.component.html',
  styleUrls: ['./courier-details.component.css'],
})
export class CourierDetailsComponent implements OnInit {
  @Input() tarjetaSeleccionadaDatos: Delivery;
  account: Account;
  isLoading = true;
  mostrar: number;
  accountForm: FormGroup;
  reviews: Reviews[] = [];
  totalReviews: number;
  delivery: Delivery;
  visible = environment.hidden;
  baseUrl: string;
  time: number;

  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private reviewsService: ReviewsService,
    private router: Router,
    private dataServiceService: DataServiceService,
    private dialog: MatDialog
  ) {
    this.baseUrl = environment.apiUrl.replace('api/', '');
    this.time = new Date().getUTCMilliseconds();
  }

  ngOnInit(): void {
    this.delivery = this.dataServiceService.getDelivery;
    if (this.delivery === undefined) {
      this.router.navigate(['/search-courier']);
    }
    this.reviewsService
      .searchReviews(this.delivery.userId)
      .subscribe((data) => {
        this.reviews = data;
        this.totalReviews = this.reviews.length;
      });
    this.accountForm = this.formBuilder.group({
      from: new FormControl(),
      to: new FormControl(),
      email: new FormControl(),
      phoneNumber: new FormControl(),
      location: new FormControl(),
      website: new FormControl(),
      serviceDescription: new FormControl(),
      price: new FormControl(),
      librasDisponibles: new FormControl(),
      dineroDisponible: new FormControl(),
      entregaMinima: new FormControl(),
      porCada: new FormControl(),
      entrego: new FormControl(),
      parcelDropoffAddress: new FormControl(),
      hoursAvailable: new FormControl(),
      parcelPickupAddress: new FormControl(),
      telefonosALlevar: new FormControl(),
      precioPorTelefono: new FormControl(),
    });
    this.accountService.getAccountByUserId(this.delivery.userId).subscribe(
      (accountData: Account) => {
        this.account = accountData;
        this.llenarFormulario();
        this.isLoading = false;
      },
      () => {
        this.account = null;
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
    this.mostrar = 0;
  }

  llenarFormulario() {
    this.accountForm.controls.from.setValue(
      this.delivery.fromPlace.formattedAddress
    );
    this.accountForm.controls.to.setValue(
      this.delivery.toPlace.formattedAddress
    );
    this.accountForm.controls.email.setValue(this.account.email);
    this.accountForm.controls.phoneNumber.setValue(this.account.phoneNumber);
    this.accountForm.controls.website.setValue(this.account.website);
    this.accountForm.controls.price.setValue(this.delivery.price);
    this.accountForm.controls.librasDisponibles.setValue(
      this.delivery.librasDisponibles
    );
    this.accountForm.controls.serviceDescription.setValue(
      this.delivery.comment
    );
    this.accountForm.controls.dineroDisponible.setValue(
      this.delivery.dineroDisponible
    );
    this.accountForm.controls.entregaMinima.setValue(
      this.delivery.entregaMinima
    );
    this.accountForm.controls.porCada.setValue(this.delivery.porCada);
    this.accountForm.controls.entrego.setValue(this.delivery.entrego);
    this.accountForm.controls.parcelDropoffAddress.setValue(
      this.delivery.parcelDropoffAddress
    );
    this.accountForm.controls.hoursAvailable.setValue(
      this.delivery.hoursAvailable
    );
    this.accountForm.controls.parcelPickupAddress.setValue(
      this.delivery.parcelPickupAddress
    );
    this.accountForm.controls.telefonosALlevar.setValue(
      this.delivery.telefonosALlevar
    );
    this.accountForm.controls.precioPorTelefono.setValue(
      this.delivery.precioPorTelefono
    );
  }

  volver() {
    this.router.navigate(['/search-courier']);
  }

  transferirData() {
    this.mostrar = 2;
  }

  comprar() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '45%';
    this.delivery.sellerMail = this.account.email;
    dialogConfig.data = this.delivery;

    const dialogRef = this.dialog.open(BuyDialogComponent, dialogConfig);
  }

  getRatingButtonText() {
    if (this.totalReviews === 0) {
      return 'Agrega una valoracion';
    } else if (this.totalReviews === 1) {
      return 'Ver' + ' ' + this.totalReviews + ' ' + 'valoracion';
    }
    return 'Ver' + ' ' + this.totalReviews + ' ' + 'valoraciones';
  }
}
