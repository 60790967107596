import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ToastrService } from 'ngx-toastr';
import { Delivery } from 'src/app/models/Delivery';
import { Place } from 'src/app/models/Place';
import { DeliveryService } from 'src/app/services/delivery.service';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import _ from 'lodash';
import { environment } from 'src/environments/environment';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-add-or-edit-service',
  templateUrl: './add-or-edit-delivery-dialog.component.html',
  styleUrls: ['./add-or-edit-delivery-dialog.component.scss'],
})
export class AddOrEditDeliveryDialogComponent implements OnInit {
  public form: FormGroup;
  isLoading = true;
  isNewDelivery = this.data === null || this.data.deliveryId === null;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  options: any = {
    types: ['(cities)'],
  };
  fromPlace: Place;
  toPlace: Place;
  dateNow = Date.now;
  minDepartureDate = new Date();
  minMinArrivalDate: Date;
  visible = environment.hidden;
  public formPayment: FormGroup;
  successCreatingTrip = true;
  checkboxCity: boolean = false;
  checkboxDays: boolean = false;
  disabled: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddOrEditDeliveryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Delivery,
    private deliveryService: DeliveryService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {
    this.fromPlace = data?.fromPlace;
    this.toPlace = data?.toPlace;
  }

  ngOnInit() {
    this.form = this.fb.group({
      from: [this.data?.fromPlace.formattedAddress, Validators.required],
      to: [this.data?.toPlace.formattedAddress, Validators.required],
      departure: [new Date(this.data?.departure)],
      price: [this.data?.price, [Validators.pattern('^[0-9]+')]],
      librasDisponibles: [
        this.data?.librasDisponibles,
        [Validators.pattern('^[0-9]+')],
      ],
      comment: [this.data?.comment],
      dineroDisponible: [
        this.data?.dineroDisponible,
        [Validators.pattern('^[0-9]+')],
      ],
      entregaMinima: [
        this.data?.entregaMinima,
        [Validators.pattern('^[0-9]+')],
      ],
      porCada: [this.data?.porCada, [Validators.pattern('^[0-9]+')]],
      entrego: [this.data?.entrego, [Validators.pattern('^[0-9]+')]],
      parcelDropoffAddress: [this.data?.parcelDropoffAddress],
      hoursAvailable: this.data?.hoursAvailable,
      parcelPickupAddress: [this.data?.parcelPickupAddress],
      telefonosALlevar: [
        this.data?.telefonosALlevar,
        [Validators.pattern('^[0-9]+')],
      ],
      precioPorTelefono: [
        this.data?.precioPorTelefono,
        [Validators.pattern('^[0-9]+')],
      ],
    });
    this.formPayment = this.fb.group({});
    this.isLoading = false;
    const currentYear = new Date().getFullYear();
    if (
      this.data != null &&
      this.data.toPlace.formattedAddress === 'Toda Cuba'
    ) {
      this.toggleCity(true);
    }
    if (this.data != null && this.data.departure === null) {
      this.toggleDays(true);
    }
  }

  save() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    const delivery = this.form.value as Delivery;
    delivery.fromPlace = this.fromPlace;
    delivery.toPlace = this.toPlace;
    delivery.gananciaPorCiento = 10;
    if (this.isNewDelivery) {
      delivery.isActive = true;
      this.deliveryService.addNewDelivery(delivery).subscribe(
        (deliveryId: number) => {
          delivery.deliveryId = deliveryId;
          this.data = delivery;
          this.isLoading = false;
        },
        (error) => {
          this.successCreatingTrip = false;
          this.isLoading = false;
        }
      );
    } else {
      this.data = { ...this.data, ...delivery };
      this.deliveryService.updateDelivery(this.data).subscribe(
        () => {
          this.toastr.success('Ruta de entrega actualizada con éxito');
          this.dialogRef.close(this.data);
          this.isLoading = false;
        },
        (error) => {
          this.toastr.error(error.message);
          this.isLoading = false;
        }
      );
    }
  }

  delete() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Está seguro de que desea eliminar esta ruta de entrega?',
        buttonText: {
          ok: 'Ok',
          cancel: 'Cancel',
        },
      },
    });

    dialogRef.afterClosed().subscribe((delt) => {
      if (delt) {
        this.isLoading = true;
        this.deliveryService.deleteDelivery(this.data.deliveryId).subscribe(
          () => {
            this.toastr.success('Ruta de entrega eliminada con éxito.');
            this.isLoading = false;
            this.dialogRef.close(null);
          },
          (error) => {
            this.toastr.error(error.message);
            this.isLoading = false;
            this.dialogRef.close(this.data);
          }
        );
      }
    });
  }

  public handleAddressChange(address: Address, field: string) {
    if (!address.formatted_address) {
      return;
    }

    if (field === 'from') {
      this.fromPlace = this.initPlaceObj(address);
      this.form.controls.from.setValue(address.formatted_address);
    } else if (field === 'to') {
      this.toPlace = this.initPlaceObj(address);
      this.form.controls.to.setValue(address.formatted_address);
    }
  }

  initPlaceObj(address: Address): Place {
    const place = {} as Place;
    place.formattedAddress = address.formatted_address;
    place.googleAddressJSON = JSON.stringify(address);
    place.googlePlaceId = address.place_id;
    place.latitude = address.geometry.location.lat();
    place.longitude = address.geometry.location.lng();
    place.country = address.address_components.find((a) =>
      _.includes(a.types, 'country')
    )?.long_name;
    return place;
  }

  closeDialog() {
    this.dialogRef.close(this.data);
  }

  toggleCity(event: any) {
    this.checkboxCity = event;
    if (this.checkboxCity === true) {
      this.form.controls.to.setValue('Toda Cuba');
      this.toPlace = null;
    } else {
      this.form.controls.to.setValue(null);
    }
  }

  toggleDays(event: any) {
    this.checkboxDays = event;
    if (this.checkboxDays === true) {
      this.disabled = true;
      this.form.controls.departure.reset();
    } else {
      this.disabled = false;
    }
  }
}
