import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/main/confirmation-dialog/confirmation-dialog.component';
import { Account } from 'src/app/models/Account';
import { Delivery } from 'src/app/models/Delivery';
import { Reviews } from 'src/app/models/Reviews';
import { AccountService } from 'src/app/services/account.service';
import { AuthService } from 'src/app/services/auth.service';
import { ReviewsService } from 'src/app/services/reviews.service';
import { AddReviewComponent } from '../add-review/add-review.component';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css'],
})
export class ReviewComponent implements OnInit {
  mostrar: boolean;
  userId: number;
  @Input() deliveryDetails: Delivery;
  @Input() reviewSeleccionado: Reviews[];
  displayName = new FormControl('');
  filteredOptions: Observable<Account[]>;
  names: Account[];

  constructor(
    private dialog: MatDialog,
    private reviewsService: ReviewsService,
    private authService: AuthService,
    private route: Router,
    private accountService: AccountService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.mostrar = false;
    this.filteredOptions = this.displayName.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value || ''))
    );
    this.displayName.setValue(this.deliveryDetails.account.displayName);
    this.userId = this.deliveryDetails.userId;
  }

  volver() {
    this.mostrar = true;
  }

  addValoracion() {
    if (this.authService.currentUserValue == null) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '45%',
        disableClose: true,
        autoFocus: true,
        data: {
          message:
            'Para agregar una valoración debe iniciar sesión, presione Si si desea ir al inicio de sesión.',
          buttonText: {
            ok: 'Si',
            cancel: 'No',
          },
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.route.navigate(['/login']);
        }
      });
    } else if (this.userId === this.authService.currentUserId) {
      this.toastr.error(
        'Usted no puede crear una valoracion para su propio perfil.'
      );
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = this.userId;
      const dialogRef = this.dialog.open(AddReviewComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(() => {
        this.reviewsService.searchReviews(this.userId).subscribe((data) => {
          this.reviewSeleccionado = data;
        });
      });
    }
  }

  search(displayName: string) {
    this.userId = this.names.find((n) => n.displayName === displayName)?.userId;

    this.reviewsService.searchReviews(this.userId).subscribe((data) => {
      this.reviewSeleccionado = data;
    });
  }

  filter(value: string): Account[] {
    const account = [];
    const filterValue = value.toLowerCase();
    this.accountService
      .getAccountByUserName(filterValue)
      .subscribe((data: Account[]) => {
        this.names = [];
        data.forEach((element) => {
          account.push(element);
          this.names.push(element);
        });
      });
    return account;
  }
}
