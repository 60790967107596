<div
  fxLayout="row"
  fxLayoutAlign="center center"
  style="margin-top: 10px"
  cdkScrollable
>
  <mat-card fxFlex="100">
    <mat-card-header>
      <mat-card-title>Perfil</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group mat-align-tabs="center">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>people</mat-icon>
            <span fxShow="true" fxHide.gt-sm="true">Publico</span>
            <span fxHide="true" fxShow.gt-sm="true">Informacion Publica</span>
          </ng-template>
          <form
            class="test-class"
            fxLayout="column"
            fxLayoutAlign="start"
            [formGroup]="accountForm"
            (ngSubmit)="onSaveChanges()"
          >
            <div fxLayout.gt-md="row" fxLayout="column">
              <div
                fxLayout="column"
                fxFlex="50%"
                class="profile-img-content-wrapper"
              >
                <div *ngIf="imagenFotoPerfil !== null && cargaImagen">
                  <img
                    mat-card-avatar
                    src="{{ baseUrl }}{{ imagenFotoPerfil }}?{{ time }}"
                    class="upload-img"
                  />
                </div>
                <div *ngIf="imagenFotoPerfil && !cargaImagen">
                  <img
                    mat-card-avatar
                    [src]="imagenFotoPerfil"
                    alt="Profile Img"
                    class="upload-img"
                  />
                </div>
                <div
                  *ngIf="!imagenFotoPerfil"
                  mat-card-avatar
                  class="profile-space-holder-image upload-img"
                ></div>
                <div
                  class="profile-picture-footer"
                  fxLayout="column"
                  fxLayoutAlign="center center"
                >
                  <div>
                    <mat-label>Foto de Perfil</mat-label>
                    <!-- <Small>(Opcional)</Small><br /> -->
                  </div>
                  <div>
                    <input
                      type="file"
                      (change)="uploadProfilePicture($event)"
                      accept="image/*"
                      class="img-load"
                    />
                  </div>
                </div>
              </div>
              <div
                fxFlex="50"
                appearance="outline"
                class="pl-4"
                [hidden]="visible"
              >
                <mat-label>Sube una foto de tu identificación.</mat-label><br />
                <Small>*(Este requisito es obligatorio)</Small>
                <div fxLayout="row">
                  <mat-icon>file_upload</mat-icon>
                  <input
                    type="file"
                    (change)="uploadFileEvt($event)"
                    multiple="multiple"
                    accept="image/*"
                  />
                </div>
              </div>
            </div>
            <div fxLayout.gt-sm="row" fxLayout="column">
              <mat-form-field
                fxFlex="50"
                appearance="outline"
                class="pr-4 width-100"
              >
                <mat-label>Nombre para mostrar al publico</mat-label>
                <input
                  matInput
                  #name
                  placeholder="Company or name to display to the public"
                  formControlName="displayName"
                  (keyup)="verificarDisplayName($event)"
                />
                <mat-error
                  *ngIf="accountForm.controls['displayName'].errors?.required"
                  >El nombre para mostrar es requerido!</mat-error
                >
                <mat-error
                  *ngIf="
                    !validDisplayName &&
                    accountForm.controls['displayName'].value.length > 3
                  "
                  >Ese nombre ya existe, escoja otro!</mat-error
                >
                <mat-error
                  *ngIf="
                    accountForm.controls['displayName'].hasError('minlength')
                  "
                >
                  El nombre debe de tener minimo 4 caracteres.
                </mat-error>
                <mat-icon matSuffix>group</mat-icon>
              </mat-form-field>
              <mat-form-field
                fxFlex="50"
                appearance="outline"
                class="pr-4 width-100"
              >
                <mat-label>Numero de telefono</mat-label>
                <input
                  matInput
                  placeholder="Numero de telefono"
                  formControlName="phoneNumber"
                />
                <mat-icon matSuffix>phone</mat-icon>
                <mat-error
                  *ngIf="
                    accountForm.controls['phoneNumber'].hasError('pattern')
                  "
                >
                  Solo puede tener números.
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="1 0 auto">
              <mat-form-field
                fxFlex="50"
                appearance="outline"
                class="pr-4 width-100"
              >
                <mat-label>Correo de contacto</mat-label>
                <input
                  matInput
                  placeholder="Correo de contacto"
                  formControlName="email"
                />
                <mat-icon matSuffix>mail</mat-icon>
                <mat-error
                  *ngIf="accountForm.controls['email'].errors?.required"
                  >El correo es requerido!</mat-error
                >
                <mat-error *ngIf="accountForm.controls['email'].errors?.email"
                  >Por favor , entre un correo valido!</mat-error
                >
              </mat-form-field>
              <mat-form-field
                fxFlex="50"
                appearance="outline"
                class="pr-4"
                [hidden]="visible"
              >
                <mat-label>Pagina Web (opcional)</mat-label>
                <input
                  matInput
                  placeholder="Website (optional)"
                  formControlName="website"
                />
                <mat-icon matSuffix>web_asset</mat-icon>
              </mat-form-field>
            </div>
            <div
              fxLayout.gt-md="row"
              fxLayout="column"
              fxFlex="1 0 auto"
              [hidden]="visible"
            >
              <mat-form-field appearance="outline" class="pr-4 width-100">
                <mat-label>Zelle email o numero de telefono</mat-label>
                <input
                  matInput
                  placeholder="Zelle email o numero de telefono"
                  formControlName="zelleInformation"
                />
                <mat-icon matSuffix>web_asset</mat-icon>
                <mat-error
                  *ngIf="
                    accountForm.controls['zelleInformation'].errors?.required
                  "
                  >la información del Zelle es requerida.</mat-error
                >
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxFlex="1 0 auto">
              <mat-form-field fxFlex="100" appearance="outline">
                <mat-label>Agrega un comentario sobre tu servicio</mat-label>
                <textarea
                  rows="5"
                  matInput
                  placeholder="Ex. I have many years delivering..."
                  formControlName="serviceDescription"
                ></textarea>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="end end"
              style="margin-top: 2%; margin-bottom: 20px"
            >
              <button
                mat-raised-button
                color="primary"
                type="submit"
                [disabled]="!accountForm.valid"
              >
                Guardar
              </button>
            </div>
          </form>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>verified_user</mat-icon
            ><span fxShow="true" fxHide.gt-sm="true">Privada</span>
            <span fxHide="true" fxShow.gt-sm="true">Informacion Privada</span>
          </ng-template>
          <form
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="1 0 auto"
            [formGroup]="privateForm"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxFlex="1 0 auto"
              [hidden]="visible"
            >
              <mat-form-field fxFlex="50" appearance="outline">
                <mat-label>Usuario</mat-label>
                <input matInput placeholder="Usuario" formControlName="user" />
                <mat-icon matSuffix>person</mat-icon>
                <mat-error *ngIf="privateForm.controls['user'].errors?.required"
                  >El usuario no puede estar vacio</mat-error
                >
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxFlex="1 0 auto">
              <mat-form-field
                fxFlex="70"
                fxFlex.gt-sm="50"
                appearance="outline"
              >
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" formControlName="email" />
                <mat-icon matSuffix>mail</mat-icon>
                <mat-error
                  *ngIf="privateForm.controls['email'].errors?.required"
                  >El email no puede estar vacio.</mat-error
                >
                <mat-error *ngIf="privateForm.controls['email'].errors?.email"
                  >Entre un email correcto.</mat-error
                >
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxFlex="1 0 auto">
              <mat-form-field
                fxFlex="70"
                fxFlex.gt-sm="50"
                appearance="outline"
              >
                <mat-label>Contraseña Actual</mat-label>
                <input
                  matInput
                  #passInput
                  placeholder="Old Password"
                  [type]="passActualHide ? 'password' : 'text'"
                  formControlName="currentPass"
                  (focusout)="verificarPass(passInput.value)"
                />
                <mat-icon
                  matSuffix
                  (click)="passActualHide = !passActualHide"
                  class="cursor-pointer"
                >
                  {{ passActualHide ? "visibility_off" : "visibility" }}
                </mat-icon>
                <mat-error
                  *ngIf="privateForm.controls['currentPass'].errors?.required"
                >
                  Las contraseña actual no es correcta.
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxFlex="1 0 auto">
              <mat-form-field
                fxFlex="70"
                fxFlex.gt-sm="50"
                appearance="outline"
              >
                <mat-label>Nueva Contraseña</mat-label>
                <input
                  matInput
                  placeholder="Password"
                  [type]="passNuevoHide ? 'password' : 'text'"
                  formControlName="newPass"
                  (keyup)="validPass($event)"
                />
                <mat-icon
                  matSuffix
                  (click)="passNuevoHide = !passNuevoHide"
                  class="cursor-pointer"
                >
                  {{ passNuevoHide ? "visibility_off" : "visibility" }}
                </mat-icon>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxFlex="1 0 auto">
              <mat-form-field
                fxFlex="70"
                fxFlex.gt-sm="50"
                appearance="outline"
              >
                <mat-label>Confirmar Contraseña</mat-label>
                <input
                  matInput
                  placeholder="{{
                    validpass ? 'Contraseña confirmada' : 'Confirmar contraseña'
                  }}"
                  [type]="passNuevoHide ? 'password' : 'text'"
                  formControlName="confirmPass"
                  (keyup)="validPass($event)"
                />
                <mat-icon
                  matSuffix
                  *ngIf="!validpass"
                  (click)="passNuevoHide = !passNuevoHide"
                  class="cursor-pointer"
                >
                  {{ passNuevoHide ? "visibility_off" : "visibility" }}
                </mat-icon>
                <mat-icon matSuffix *ngIf="validpass" style="color: green"
                  >done</mat-icon
                >
                <mat-error
                  *ngIf="
                    privateForm.controls['confirmPass'].hasError('mustMatch')
                  "
                >
                  Las contraseñas deben de ser iguales.
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="end end" style="margin-top: 2%">
              <button
                mat-raised-button
                color="primary"
                type="submit"
                [disabled]="
                  !privateForm.valid ||
                  (privateForm.controls['currentPass'].value !== '' &&
                    privateForm.controls['newPass'].value === '') ||
                  (privateForm.controls['currentPass'].value === '' &&
                    privateForm.controls['newPass'].value !== '')
                "
                (click)="onSaveChangesPrivate()"
              >
                Guardar
              </button>
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
<div
  *ngIf="isLoading"
  fxLayoutAlign="center"
  style="position: absolute; top: 40%; right: 35%; left: 35%"
>
  <mat-spinner></mat-spinner>
</div>
