import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Delivery } from '../models/Delivery';
import { DeliverySale } from '../models/DeliverySale';
import { DeliverySaleCodeDto } from '../models/DeliverySaleCodeDto';
import { DeliverySaleDto } from '../models/DeliverySaleDto';

@Injectable({
  providedIn: 'root',
})
export class DeliverySaleService {
  private baseUrl = environment.apiUrl;
  private controllerName = 'deliverysale';
  constructor(private http: HttpClient) {}

  createDelivery(model: DeliverySaleDto): Observable<any> {
    return this.http.post(
      this.baseUrl + this.controllerName + '/createDelivery/',
      model
    );
  }

  getDeliverySaleByDeliveryId(deliveryId: number): Observable<DeliverySale> {
    return this.http
      .get(
        this.baseUrl + this.controllerName + '/getDeliverySale/' + deliveryId
      )
      .pipe(map((result: DeliverySale) => result));
  }

  validateAndUpdateDeliverySaleCode(
    model: DeliverySaleCodeDto
  ): Observable<any> {
    return this.http
      .post(
        this.baseUrl + this.controllerName + '/updateDeliverySaleCodes/',
        model
      )
      .pipe(map((result: DeliverySale[]) => result));
  }

  validateDeliverySaleCode(model: DeliverySaleCodeDto): Observable<any> {
    return this.http.post(
      this.baseUrl + this.controllerName + '/validateDeliverySaleCodes/',
      model
    );
  }

  sendDeliveryCodesPayment(model: DeliverySaleCodeDto): Observable<any> {
    return this.http.post(
      this.baseUrl + this.controllerName + '/sendDeliveryCodePayments/',
      model
    );
  }

  getDeliverySalePayment(userId: number): Observable<any> {
    return this.http.post(
      this.baseUrl + this.controllerName + '/getDeliverySalePaymentByUserId/',
      userId
    );
  }
}
