<div class="pad">
  <div class="cl-b">
    <button mat-button color="primary" (click)="post()">
      {{ mensaje }}
    </button>
  </div>
  <div *ngIf="id == '1'">
    <div *ngIf="mensaje == 'Español'">
      <div>
        <h1>Terms and Conditions</h1>
      </div>
      <p>
        The general conditions contained in this legal notice regulate the
        access and use of the website of sentrega.com. Access to it implies
        unreserved, express and full acceptance of these conditions from the
        moment you access the Website, the moment in which you also acquire the
        status of user thereof. The use of certain services offered on this site
        will also be governed by the particular conditions provided in each
        case, which will be understood as accepted by the mere use of such
        services. Consequently, any use of the Website implies acceptance of
        these conditions, which the user is obliged to read in each of the
        accesses made. Notwithstanding the foregoing, it is possible that in
        certain cases the user must accept particular conditions of a specific
        nature, which complement, replace or render ineffective, in whole or in
        part, the general conditions, and that the user also declares to know
        and expressly accept, in the same terms regulated in the preceding
        paragraph for them.By accessing, browsing or downloading any part of
        this website or its content, or by posting or uploading materials to
        this website, or through any other use of this website and its contents,
        whether as a registered Customer, or an unregistered Visiting User , You
        agree to comply with these Terms and Conditions, without modification,
        (which constitutes a contract between you and the Company), as well as
        all applicable laws. The user agrees not to enter this web portal
        through any other means other than the interface of the company
        sentrega.com .These Terms may include provisions regarding features or
        Services that are not (and are not intended to be) available through
        this website. Until the moment in which any provision contemplated in
        these Terms refers solely and exclusively to said functionalities or
        services, its compliance will not be mandatory and will not affect the
        validity of the remaining provisions.
      </p>
      <div>
        <h1>Privacy statement</h1>
      </div>
      <p>
        At sentrega.com we have created a series of security measures to keep
        your personal information safe. We have a secure server and all the
        information you provide us in a confidential manner is transmitted
        through Secure Socket Layer (SSL) and encrypted on our platform, being
        only accessible by security systems upon your request. Sentrega.com
        reserves the right at all times to disclose any information as it deems
        necessary to comply with any regulation, legal process, government
        request or applicable law, and to edit, refuse to post, remove or
        restrict any information or materials, in whole or in part. part, at the
        sole discretion of sentrega.com By using our services, you agree to this
        privacy policy.
      </p>
      <div>
        <h1>Confidentiality</h1>
      </div>
      <p>
        The sentrega.com company guarantees for its clients the protection of
        all personal data that is provided, and that is strictly required for
        management or that is requested by customs, government or federal
        financial entities. In this sense, we guarantee: All personal data that
        is provided by the user to our website will be integrated into our
        electronic files, which are created and protected under our full
        responsibility. The information is requested for the administrative and
        commercial procedures of our client portfolio. At the time of requesting
        and handling personal data, appropriate security measures have been
        taken in order to prevent loss, unauthorized access or manipulation of
        the same. Sentrega.com is committed to providing protection to the
        personal and confidential information to which it has access. The data
        provided may be granted to competent public entities in the matter,
        provided that other legislation requires or allows it. For no reason,
        the company will use the data you provide to offer services other than
        carrying out administrative and commercial management of the client
        portfolio. At all times, the client may exercise their rights of access,
        rectification, cancellation and opposition to their personal data
        through a written document. When the client provides sentrega.com with
        any type of personal data related to the transactions inherent to this
        web page that merit some type of payment, the company may transmit
        personal data to third parties (credit and surety appraisal agencies or
        third parties that are part of in some way in the prevention of crime),
        in order to be able to process the approval of a credit card or other
        payment methods or to combat crime, as well as to processing agencies
        for payment by credit card or other means. Personal information provided
        for these purposes by customers outside the United States will always be
        provided to US institutions for processing and related purposes.
      </p>
      <div>
        <h1>Use of cookies</h1>
      </div>
      <p>
        By entering the sentrega.com website, the user accepts and agrees that
        the company, its associates or its service providers can send cookies
        (small pieces of information that are located on the user's hard drive
        when enter a web page or certain parts of a web page) to your computer
        or other access device in order to personalize your visit to this web
        page or to better understand your interests or browsing habits while
        visiting the page. The cookie may contain personal information related
        to the user, which may be indefinite or for a single session. If the
        client wishes, they can configure their browser to receive a
        notification on the screen about the reception of cookies and prevent
        the installation of cookies on their hard drive. For more information,
        check your browser's instructions.
      </p>
      <div>
        <h1>Communication policy and notifications to users</h1>
      </div>
      <p>
        As part of the sentrega.com Services, you agree to receive alerts on
        promotions and other information of interest through text messages with
        the mobile phone number or email that you provide us. The number of
        messages will depend on how you use our service. sentrega.com does not
        charge a fee for this service, but your phone company may apply message
        and data rates. If you have questions, you can contact Customer Service
        by phone ________, email servicioalcliente@sentrega.com In order to be
        able to provide and offer our services, your data may be used to notify
        about: The management and control of your account at www. sentrega.com
        The management and control of the transactions carried out, where
        appropriate, through www. sentrega.com The periodic sending, by
        electronic means, of information related to our offers and the plans
        offered on our website (special rates, promotions, contests, etc.) You
        can send your requests for access, rectification, cancellation and
        opposition (in particular, not to receive our commercial communications)
        to our registered office or to the email servicioalcliente@sentrega.com
      </p>
      <div>
        <h1>Disclaimer, exclusions and limitations</h1>
      </div>
      <p>
        All content and Services offered through the website will be provided
        "as is" and exactly as they are available, for which sentrega.com
        expressly waives and within the parameters of the applicable Law all
        guarantees and representations of any kind, in relation to the Content
        and Services of the website, whether express or implied, including the
        implied warranties of merchantability, satisfactory quality, fitness for
        a particular purpose and non-infringement of the intellectual property
        rights of other manufacturers. sentrega.com does not guarantee that the
        Content of the web page is adequate, available, allowed to be used in
        all locations or that it is free of viruses, or in what is related to
        its interoperability with any hardware, software or content or the
        ability of the User to access or interact through this website with
        other service providers, networks, users or resources of a computer or
        informative nature through the Internet. Anyone who accesses this web
        portal and uses its content or Services is responsible for complying
        with all applicable laws and regulations, including relevant local laws.
        Even though the company has tried to verify the reliability, accuracy
        and updating of all the contents of the website, including products,
        services, support and other data and descriptions obtained from third
        parties, it does not grant any type of guarantee related to the accuracy
        of any statement made on this website. Sentrega.com does not undertake
        to keep updated all the content of the website, and consequently
        disclaims any liability for errors or omissions that may be in the
        Content of the website. Any action based on information contained in the
        Website Content (or User Content) is the sole responsibility of the
        User. Sentrega.com does not undertake to keep all the Content of the web
        page updated, and therefore disclaims any responsibility for errors or
        omissions that may exist in the Content of the web page (except to the
        extent that such disclaimer is incompatible with the guarantees and
        other terms and conditions related to the sale of products or services,
        if any, by sentrega.com. Sentrega.com does not fully guarantee that the
        functions or Services available on the website or other User
        interactions with the website are constantly available, uninterrupted,
        completely secure or error-free, that defects will be corrected or that
        this web page or the server that facilitates its access are free of
        viruses or other harmful or harmful components. Sentrega.com and its
        associated stores for no reason are responsible for any damage or loss
        of data derived, or any virus that may cause damage to your computer
        equipment or other property due to access to this website or its use,
        including downloading of images, software and other types of content on
        the website. If the use of the Content of the website results in the
        need to maintain, The company is not responsible when service
        interruptions or malfunctions occur due to unforeseeable events, force
        majeure or other reasons that are beyond its control. This includes
        interruptions and delays in equipment deliveries, strikes, and other
        causes beyond our control. Sentrega.com is obliged to comply with the
        rules and regulations in force in the countries to which the cargo is
        sent, and is not responsible for any change in its import regulations or
        the denial of entry of any item in a country. The information contained
        in the website, as well as the user content, does not represent legal,
        professional, financial or medical advice; therefore, it is not reliable
        and you should not trust the content of the website that contains
        forward-looking statements or any type of statements of intent.
        Sentrega.com reserves the right not to act in accordance with these
        statements. This website may contain references to specific products and
        services that may or may not be available in a specific country or
        location. Any reference shall not be construed as an implication or
        guarantee that such products or services are available at any time in a
        particular country. To the extent that current legislation limits or
        prohibits the exemption of guarantees to Sentrega.com, previously
        indicated, Sentrega.com excludes, waives and limits such guarantees only
        to the maximum extent permitted by applicable law. When Users can carry
        out transactions with a third party (including other users or a service
        provider or a seller of products) through this web page or any linked
        page that is not Sentrega.com, this transaction and any rights or
        obligations arising from it arising will be exclusively between you and
        the third party.
      </p>
      <div>
        <h1>Payments</h1>
      </div>
      <p>
        Sentrega.com will not process any order or transaction until the
        customer has completed the payment in full, which may be made
        electronically through a debit or credit card. Sentrega.com will enable
        a bank account in which the money will be deposited. Once payment is
        done, the person sending the goods will receive a code. This code will
        be provided to the person delivering the goods upon successful
        completion of the delivery. Once the person providing the delivery
        service gets the delivery code, they can claim the money on
        Sentrega.com. Money will be transferred to the bank account provided by
        the person once they enter the valid code. If the delivery is not
        successful, Sentrega.com will return the money to the client sending the
        goods. At Sentrega.com clients are our priority and we thrive to provide
        a great customer experience. Sentrega.com will charge a %5.0 for each
        payment through the website. This amount is not refundable even if the
        transaction between the two parties is not successful.
      </p>
      <div>
        <h1>Cancellation policy</h1>
      </div>
      <p>
        Customers may request online cancellation of their orders through the
        Sentrega.com website as long as the deposit has been made in the
        authorized bank account and both parties agree to make said
        cancellation, or by means of an email issued from your electronic
        address registered on the company's website, will be made within fifteen
        (15) business days after the date of approval of said cancellation.
        Cancellations will have a surcharge of five percent (5%) of the total
        value of the order. It will not be possible to cancel orders once the
        loads are in transit to their destination. Returns or compensation for
        any concept will not be borne by the company
      </p>
      <div>
        <h1>Copyright Notice</h1>
      </div>
      <p>
        Copyright, as well as other intellectual property rights exist in all
        content related to the services offered by the company and present on
        this website.
      </p>
      <div>
        <h1>Force Majeure Events</h1>
      </div>
      <p>
        The parties will not be responsible for any breach of their obligations
        that occur due to events beyond their control, such as Terrorism, War,
        Insurrection Policy, Strikes, Uprising, Civil Conflicts, Law of Civil or
        Military Authority, Earthquake, Floods or any other natural or man-made
        event, and even the death of the recipient in the event that it is a
        natural person, will result in the cancellation of the agreement. Either
        party affected by such events shall promptly notify the other party and
        shall use reasonable efforts to comply with the terms and conditions of
        any agreement contemplated in the contract.
      </p>
      <div>
        <h1>Applicable Law and Jurisdiction Provision</h1>
      </div>
      <p>
        This agreement shall be governed by the laws of the State of Florida,
        and the parties hereby submit to the jurisdiction of the courts of the
        State of Florida and the exclusive jurisdiction of the judicial circuit
        of Miami-Dade County, Florida, in order to litigate any of matter
        arising through or under this agreement. In the event that the Company
        retains any attorney to enforce the provisions herein, those incurred in
        any bankruptcy or appeal proceeding.
      </p>
      <div>
        <h1>Notification of changes</h1>
      </div>
      <p>
        Sentrega.com or the providers associated with this page may make
        improvements or changes in the products, support, services, utilities,
        prices or other content of the website (including these terms) at any
        time and without prior notice. Sentrega.com may notify of changes in
        Terms or any other content of the page through the publication of
        notices on the same or in other means of communication, and users who
        enter the web after said changes are published will be subject to said
        modifications as soon as the notices or the new Terms and Conditions are
        disseminated, even when the user has not visited the page that reflects
        such variations. In this sense, users are suggested to frequently
        consult the website as well as these terms in order to stay informed of
        any changes in it.
      </p>
    </div>
    <div *ngIf="mensaje == 'English'">
      <div>
        <h1>Términos y Condiciones</h1>
      </div>
      <p>
        Las condiciones generales contenidas en este aviso legal regulan el
        acceso y uso del sitio web de sentrega.com. El acceso a ella implica sin
        reservas, aceptación expresa y plena de las presentes condiciones desde
        el momento en que accede del Sitio Web, momento en el que usted también
        adquiere la condición de usuario del mismo. El uso de ciertos servicios
        ofrecidos en este sitio también será se regirá por las condiciones
        particulares previstas en cada caso, que serán se entiende aceptada por
        la mera utilización de tales servicios. En consecuencia, cualquier La
        utilización del Sitio Web implica la aceptación de las presentes
        condiciones, que el usuario se obliga a leer en cada uno de los accesos
        realizados. Sin perjuicio de la anterior, es posible que en determinados
        casos el usuario deba aceptar condiciones particulares de carácter
        específico, que complementen, sustituyan o dejar sin efecto, en todo o
        en parte, las condiciones generales, y que el usuario también declara
        conocer y aceptar expresamente, en los mismos términos regulado en el
        párrafo anterior por los mismos. Al acceder, navegar o descargando
        cualquier parte de este sitio web o su contenido, o publicando o cargar
        materiales en este sitio web, o a través de cualquier otro uso de este
        sitio web y sus contenidos, ya sea como Cliente registrado, o como
        Usuario visitante no registrado, usted acepta cumplir con estos Términos
        y Condiciones, sin modificación, (lo que constituye un contrato entre
        usted y la Compañía), así como todas las leyes aplicables. El usuario
        acepta no acceder a este portal web por cualquier otro medio que no sea
        la interfaz de la empresa sentrega.com. Estos Términos pueden incluir
        disposiciones relativas a características o Servicios que no están (y no
        están destinados a estar) disponibles a través de este sitio web. Hasta
        el momento en que cualquier disposición contemplada en estos Términos se
        refiere única y exclusivamente a dichas funcionalidades o servicios, su
        cumplimiento no será obligatorio y no afectará a la vigencia de las
        restantes disposiciones.
      </p>
      <div>
        <h1>Declaración de privacidad</h1>
      </div>
      <p>
        En sentrega.com hemos creado una serie de medidas de seguridad para que
        tu información personal segura. Tenemos un servidor seguro y toda la
        información usted nos proporciona de manera confidencial se transmite a
        través de Secure Socket Layer (SSL) y encriptada en nuestra plataforma,
        siendo solo accesible por sistemas de seguridad a su solicitud.
        Sentrega.com se reserva el derecho en todo veces para divulgar cualquier
        información que considere necesaria para cumplir con cualquier
        regulación, proceso legal, solicitud del gobierno o ley aplicable, y a
        editar, negarse a publicar, eliminar o restringir cualquier información
        o material, en en su totalidad o en parte. parte, a la sola discreción
        de sentrega.com Al usar nuestros servicios, usted acepta esta política
        de privacidad.
      </p>
      <div>
        <h1>Confidencialidad</h1>
      </div>
      <p>
        La empresa sentrega.com garantiza a sus clientes la protección de todos
        los datos de carácter personal que se facilitan, y que son estrictamente
        necesarios para gestión o que sea solicitado por aduanas, gobierno o
        federal entidades financieras. En este sentido, garantizamos: Todos los
        datos personales que sean proporcionados por el usuario a nuestro sitio
        web se integrarán en nuestros ficheros, los cuales son creados y
        protegidos bajo nuestra total responsabilidad. los se solicita
        información para los trámites administrativos y comerciales de nuestra
        cartera de clientes. Al momento de solicitar y manejar datos personales,
        se han tomado las medidas de seguridad adecuadas para evitar pérdida,
        acceso no autorizado o manipulación de los mismos. Sentrega.com se
        compromete a brindar protección a los datos personales y a la
        información confidencial la que tiene acceso. Los datos facilitados
        podrán ser cedidos a entidades públicas competentes en la materia,
        siempre que otra legislación lo requiera o lo permita. Por ningún
        motivo, la empresa utilizará los datos que Ud. proporcione para ofrecer
        servicios distintos a la realización de tareas administrativas y Gestión
        comercial de la cartera de clientes. En todo momento, el cliente podrá
        ejercer sus derechos de acceso, rectificación, cancelación y oposición a
        sus datos personales mediante escrito. Cuando el cliente proporciona a
        sentrega.com cualquier tipo de datos personales relacionados con la
        transacciones inherentes a esta página web que ameriten algún tipo de
        pago, la empresa puede transmitir datos personales a terceros (crédito y
        garantía agencias de tasación o terceros que formen parte de alguna
        manera en el prevención del delito), para poder tramitar la aprobación
        de un tarjeta de crédito u otros métodos de pago o para combatir el
        crimen, así como para agencias procesadoras de pago con tarjeta de
        crédito u otros medios. La información personal proporcionada para estos
        fines por clientes fuera de los Estados Unidos Los estados siempre se
        proporcionarán a las instituciones estadounidenses para su procesamiento
        y propósitos relacionados.
      </p>
      <div>
        <h1>Uso de cookies</h1>
      </div>
      <p>
        Al ingresar al sitio web sentrega.com, el usuario acepta y consiente que
        el empresa, sus asociados o sus proveedores de servicios pueden enviar
        cookies (pequeñas piezas de información que se encuentran en el disco
        duro del usuario cuando ingresa una página web o ciertas partes de una
        página web) a su computadora u otra dispositivo de acceso con el fin de
        personalizar su visita a esta página web o para entender mejor sus
        intereses o hábitos de navegación mientras visita el página. La cookie
        puede contener información personal relacionada con el usuario, que
        puede ser indefinido o para una sola sesión. Si el cliente lo desea,
        pueden configurar su navegador para recibir una notificación en pantalla
        sobre la recepción de cookies y evitar la instalación de cookies en su
        disco duro. Para más información, consulte las instrucciones de su
        navegador.
      </p>
      <div>
        <h1>Política de comunicación y notificaciones a los usuarios</h1>
      </div>
      <p>
        Como parte de los Servicios de sentrega.com, usted acepta recibir
        alertas sobre promociones y otra información de interés a través de
        mensajes de texto con el número de teléfono móvil o el correo
        electrónico que nos facilites. El número de los mensajes dependerán de
        cómo utilice nuestro servicio. sentrega.com no cobrar una tarifa por
        este servicio, pero su compañía telefónica puede aplicar mensaje y tasas
        de datos. Si tiene preguntas, puede ponerse en contacto con el Servicio
        de Atención al Cliente por teléfono ________, correo electrónico
        servicioalcliente@sentrega.com Para poder para prestar y ofrecer
        nuestros servicios, sus datos podrán ser utilizados para notificarle
        sobre: La gestión y control de su cuenta en www. sentrega.com El gestión
        y control de las operaciones realizadas, en su caso, a través de www.
        sentrega.com El envío periódico, por medios electrónicos, de información
        relacionada con nuestras ofertas y los planes ofrecidos en nuestro sitio
        web (tarifas especiales, promociones, concursos, etc.) Puede enviar sus
        solicitudes de acceso, rectificación, cancelación y oposición (en
        particular, a no recibir nuestras comunicaciones comerciales) a nuestro
        domicilio social o al correo electrónico servicioalcliente@sentrega.com
      </p>
      <div>
        <h1>Descargo de responsabilidad, exclusiones y limitaciones</h1>
      </div>
      <p>
        Todos los contenidos y Servicios ofrecidos a través del sitio web se
        prestarán "como es" y exactamente como están disponibles, por lo que
        sentrega.com expresamente renuncia y dentro de los parámetros de la Ley
        aplicable a todas las garantías y manifestaciones de ningún tipo, en
        relación con los Contenidos y Servicios de el sitio web, ya sea expresa
        o implícita, incluidas las garantías implícitas de comerciabilidad,
        calidad satisfactoria, idoneidad para un propósito particular y la no
        infracción de los derechos de propiedad intelectual de otros fabricantes
        sentrega.com no garantiza que el Contenido de la web página es adecuada,
        disponible, permitida para ser utilizada en todas las ubicaciones o que
        esté libre de virus, o en lo relacionado con su interoperabilidad con
        cualquier hardware, software o contenido o la capacidad del Usuario para
        acceder o interactuar a través de este sitio web con otros proveedores
        de servicios, redes, usuarios o recursos de carácter informático o
        informativo a través de la Internet. Cualquiera que acceda a este portal
        web y utilice su contenido o Services es responsable de cumplir con
        todas las leyes aplicables y reglamentos, incluidas las leyes locales
        pertinentes. A pesar de que la empresa tiene trató de comprobar la
        fiabilidad, exactitud y actualidad de todos los contenidos del sitio
        web, incluidos productos, servicios, soporte y otros datos y
        descripciones obtenidas de terceros, no otorga ningún tipo de garantía
        relacionada con la exactitud de cualquier declaración realizada en este
        sitio web. Sentrega.com no se compromete a mantener actualizado todo el
        contenido del sitio web y, en consecuencia, se exime de cualquier
        responsabilidad por errores u omisiones que pueda haber en el Contenido
        del sitio web. Cualquier acción basada en información. contenido en el
        Contenido del sitio web (o Contenido del usuario) es el único
        responsabilidad del Usuario. Sentrega.com no se compromete a guardar
        todos el Contenido de la página web actualizado, por lo que se exime de
        cualquier responsabilidad por errores u omisiones que puedan existir en
        el Contenido de la página web (excepto en la medida en que dicho
        descargo de responsabilidad sea incompatible con las garantías y otros
        términos y condiciones relacionados con la venta de productos o
        servicios, en su caso, por sentrega.com. Sentrega.com no cubre
        completamente garantiza que las funciones o Servicios disponibles en el
        sitio web u otros Las interacciones del usuario con el sitio web están
        disponibles constantemente, ininterrumpido, completamente seguro o libre
        de errores, que los defectos serán corregido o que esta página web o el
        servidor que facilita su acceso están libres de virus u otros
        componentes dañinos o nocivos. Sentrega.com y sus tiendas asociadas por
        ningún motivo son responsables de cualquier daño o pérdida de datos
        derivada, o cualquier virus que pueda causar daños en su ordenador
        equipo u otra propiedad debido al acceso a este sitio web o su uso,
        incluida la descarga de imágenes, software y otros tipos de contenido en
        el sitio web. Si el uso del Contenido del sitio web resulta en la
        necesidad para mantener, La empresa no se hace responsable cuando las
        interrupciones del servicio o las averías se producen por caso fortuito,
        fuerza mayor u otras razones que escapan a su control. Esto incluye
        interrupciones y retrasos en las entregas de equipos, huelgas y otras
        causas ajenas a nuestra control. Sentrega.com está obligado a cumplir
        con las normas y reglamentos en vigor en los países a los que se envía
        la carga, y no está responsable de cualquier cambio en sus regulaciones
        de importación o la negación de entrada de cualquier artículo en un
        país. La información contenida en el sitio web, así como el contenido
        del usuario, no representa legal, profesional, asesoramiento financiero
        o médico; por lo tanto, no es confiable y debe no confiar en el
        contenido del sitio web que contiene información prospectiva
        declaraciones o cualquier tipo de declaración de intenciones.
        Sentrega.com se reserva la derecho a no actuar de acuerdo con estas
        declaraciones. Este sitio web puede contener referencias a productos y
        servicios específicos que pueden o no estar disponible en un país o
        ubicación específica. Cualquier referencia no será interpretarse como
        una implicación o garantía de que tales productos o servicios están
        disponibles en cualquier momento en un país en particular. En la medida
        en que la legislación vigente limita o prohíbe la exención de garantías
        a Sentrega.com, anteriormente indicado, Sentrega.com excluye, renuncia y
        limita dichas garantías sólo en la medida máxima permitida por las ley.
        Cuando los Usuarios puedan realizar transacciones con un tercero
        (incluyendo otros usuarios o un proveedor de servicios o un vendedor de
        productos) a través de este página web o cualquier página enlazada que
        no sea Sentrega.com, esta transacción y los derechos u obligaciones que
        de ella se deriven serán exclusivamente entre usted y la tercera parte.
      </p>
      <div>
        <h1>Pagos</h1>
      </div>
      <p>
        Sentrega.com no procesará ningún pedido o transacción hasta que el
        cliente ha completado el pago en su totalidad, que puede hacerse
        electrónicamente a través de una tarjeta de débito o crédito.
        Sentrega.com habilitará una cuenta bancaria en que se depositará el
        dinero. Una vez realizado el pago, la persona el envío de la mercancía
        recibirá un código. Este código será proporcionado al persona que
        entrega los bienes al completar con éxito la entrega. Una vez que la
        persona que proporciona el servicio de entrega obtiene el código de
        entrega, pueden reclamar el dinero en Sentrega.com. El dinero será
        transferido a la cuenta bancaria proporcionada por la persona una vez
        que ingresa el código válido. Si el la entrega no se realiza
        correctamente, Sentrega.com devolverá el dinero al cliente que envía la
        mercancía. En Sentrega.com los clientes son nuestra prioridad y
        prosperar para proporcionar una gran experiencia al cliente.
        Sentrega.com cobrará un %5.0 por cada pago a través del sitio web. Esta
        cantidad no es reembolsable incluso si la transacción entre las dos
        partes no tiene éxito.
      </p>
      <div>
        <h1>Política de cancelación</h1>
      </div>
      <p>
        Los clientes pueden solicitar la cancelación online de sus pedidos a
        través del sitio web Sentrega.com siempre que el depósito se haya
        realizado en el cuenta bancaria autorizada y ambas partes acuerdan
        realizar dicha cancelación, o mediante un correo electrónico emitido
        desde su dirección electrónica registrada en la página web de la
        empresa, se realizará dentro de los quince (15) días hábiles después de
        la fecha de aprobación de dicha cancelación. Las cancelaciones tendrán
        un recargo del cinco por ciento (5%) del valor total del pedido. Va a No
        será posible cancelar pedidos una vez que las cargas estén en tránsito a
        su destino. Las devoluciones o indemnizaciones por cualquier concepto no
        correrán a cargo de la empresa.
      </p>
      <div>
        <h1>Aviso de copyright</h1>
      </div>
      <p>
        Los derechos de autor, así como otros derechos de propiedad intelectual,
        existen en todos contenidos relacionados con los servicios ofrecidos por
        la empresa y presentes en este sitio web.
      </p>
      <div>
        <h1>Eventos de fuerza mayor</h1>
      </div>
      <p>
        Las partes no serán responsables del incumplimiento de sus obligaciones
        que ocurren debido a eventos fuera de su control, tales como terrorismo,
        guerra, Política de Insurrección, Huelgas, Levantamiento, Conflictos
        Civiles, Derecho Civil o Autoridad Militar, Terremoto, Inundaciones o
        cualquier otro daño natural o provocado por el hombre. evento, e incluso
        la muerte del destinatario en el caso de que se trate de un persona
        natural, dará lugar a la rescisión del contrato. O La parte afectada por
        tales eventos notificará de inmediato a la otra parte y hará todos los
        esfuerzos razonables para cumplir con los términos y condiciones de
        cualquier acuerdo contemplado en el contrato.
      </p>
      <div>
        <h1>Ley Aplicable y Disposición de Jurisdicción</h1>
      </div>
      <p>
        Este acuerdo se regirá por las leyes del Estado de Florida, y las partes
        se someten a la jurisdicción de los tribunales del Estado Florida y la
        jurisdicción exclusiva del circuito judicial de Condado de Miami-Dade,
        Florida, para litigar cualquier asunto que surja a través o bajo este
        acuerdo. En caso de que la Compañía retenga cualquier abogado para hacer
        cumplir lo aquí dispuesto, los incurridos en cualquier procedimiento de
        quiebra o apelación.
      </p>
      <div>
        <h1>Notificación de cambios</h1>
      </div>
      <p>
        Sentrega.com o los proveedores asociados a esta página pueden realizar
        mejoras o cambios en los productos, soporte, servicios, utilidades,
        precios u otro contenido del sitio web (incluidos estos términos) en
        cualquier momento y sin previo aviso. Sentrega.com puede notificar
        cambios en los Términos o cualquier otro contenido de la página mediante
        la publicación de avisos en el mismo o en otros medios de comunicación,
        y los usuarios que ingresen a la web después dichos cambios se publiquen
        estarán sujetos a dichas modificaciones tan pronto medida que se
        difundan los avisos o los nuevos Términos y Condiciones, aun cuando el
        usuario no ha visitado la página que refleja tales variaciones. En esto
        sentido, se sugiere a los usuarios que consulten con frecuencia el sitio
        web, así como estos términos con el fin de mantenerse informado de
        cualquier cambio en el mismo.
      </p>
    </div>
  </div>
  <div *ngIf="id == '2'">
    <div *ngIf="mensaje == 'Español'">
      <div>
        <h1>Cancellation policy</h1>
      </div>
      <p>
        Customers may request online cancellation of their orders through the
        Sentrega.com website as long as the deposit has been made in the
        authorized bank account and both parties agree to make said
        cancellation, or by means of an email issued from your electronic
        address registered on the company's website, will be made within fifteen
        (15) business days after the date of approval of said cancellation.
        Cancellations will have a surcharge of five percent (5%) of the total
        value of the order. It will not be possible to cancel orders once the
        loads are in transit to their destination. Returns or compensation for
        any concept will not be borne by the company.
      </p>
    </div>
    <div *ngIf="mensaje == 'English'">
      <div>
        <h1>Política de cancelación</h1>
      </div>
      <p>
        Los clientes pueden solicitar la cancelación online de sus pedidos a
        través del sitio web Sentrega.com siempre que el depósito se haya
        realizado en el cuenta bancaria autorizada y ambas partes acuerdan
        realizar dicha cancelación, o mediante un correo electrónico emitido
        desde su dirección electrónica registrada en la página web de la
        empresa, se realizará dentro de los quince (15) días hábiles después de
        la fecha de aprobación de dicha cancelación. Las cancelaciones tendrán
        un recargo del cinco por ciento (5%) del valor total del pedido. Va a No
        será posible cancelar pedidos una vez que las cargas estén en tránsito a
        su destino. Las devoluciones o indemnizaciones por cualquier concepto no
        correrán a cargo de la empresa.
      </p>
    </div>
  </div>
  <div *ngIf="id == '3'">
    <div *ngIf="mensaje == 'Español'">
      <div>
        <h1>Confidentiality</h1>
      </div>
      <p>
        The sentrega.com company guarantees for its clients the protection of
        all personal data that is provided, and that is strictly required for
        management or that is requested by customs, government or federal
        financial entities. In this sense, we guarantee: All personal data that
        is provided by the user to our website will be integrated into our
        electronic files, which are created and protected under our full
        responsibility. The information is requested for the administrative and
        commercial procedures of our client portfolio. At the time of requesting
        and handling personal data, appropriate security measures have been
        taken in order to prevent loss, unauthorized access or manipulation of
        the same. Sentrega.com is committed to providing protection to the
        personal and confidential information to which it has access. The data
        provided may be granted to competent public entities in the matter,
        provided that other legislation requires or allows it. For no reason,
        the company will use the data you provide to offer services other than
        carrying out administrative and commercial management of the client
        portfolio. At all times, the client may exercise their rights of access,
        rectification, cancellation and opposition to their personal data
        through a written document. When the client provides sentrega.com with
        any type of personal data related to the transactions inherent to this
        web page that merit some type of payment, the company may transmit
        personal data to third parties (credit and surety appraisal agencies or
        third parties that are part of in some way in the prevention of crime),
        in order to be able to process the approval of a credit card or other
        payment methods or to combat crime, as well as to processing agencies
        for payment by credit card or other means. Personal information provided
        for these purposes by customers outside the United States will always be
        provided to US institutions for processing and related purposes.
      </p>
    </div>
    <div *ngIf="mensaje == 'English'">
      <div>
        <h1>Confidencialidad</h1>
      </div>
      <p>
        La empresa sentrega.com garantiza a sus clientes la protección de todos
        los datos de carácter personal que se facilitan, y que son estrictamente
        necesarios para gestión o que sea solicitado por aduanas, gobierno o
        federal entidades financieras. En este sentido, garantizamos: Todos los
        datos personales que sean proporcionados por el usuario a nuestro sitio
        web se integrarán en nuestros ficheros, los cuales son creados y
        protegidos bajo nuestra total responsabilidad. los se solicita
        información para los trámites administrativos y comerciales de nuestra
        cartera de clientes. Al momento de solicitar y manejar datos personales,
        se han tomado las medidas de seguridad adecuadas para evitar pérdida,
        acceso no autorizado o manipulación de los mismos. Sentrega.com se
        compromete a brindar protección a los datos personales y a la
        información confidencial la que tiene acceso. Los datos facilitados
        podrán ser cedidos a entidades públicas competentes en la materia,
        siempre que otra legislación lo requiera o lo permita. Por ningún
        motivo, la empresa utilizará los datos que Ud. proporcione para ofrecer
        servicios distintos a la realización de tareas administrativas y Gestión
        comercial de la cartera de clientes. En todo momento, el cliente podrá
        ejercer sus derechos de acceso, rectificación, cancelación y oposición a
        sus datos personales mediante escrito. Cuando el cliente proporciona a
        sentrega.com cualquier tipo de datos personales relacionados con la
        transacciones inherentes a esta página web que ameriten algún tipo de
        pago, la empresa puede transmitir datos personales a terceros (crédito y
        garantía agencias de tasación o terceros que formen parte de alguna
        manera en el prevención del delito), para poder tramitar la aprobación
        de un tarjeta de crédito u otros métodos de pago o para combatir el
        crimen, así como para agencias procesadoras de pago con tarjeta de
        crédito u otros medios. La información personal proporcionada para estos
        fines por clientes fuera de los Estados Unidos Los estados siempre se
        proporcionarán a las instituciones estadounidenses para su procesamiento
        y propósitos relacionados.
      </p>
    </div>
  </div>
  <div
    *ngIf="id == '4'"
    class="justify-content-center"
    class="contactenos-wrapper"
  >
    <div *ngIf="mensaje == 'Español'">
      <div class="text-align-center">
        <h1 class="contactus">Contact Us</h1>
      </div>
      <div fxLayout="column">
        <div fxLayout="column">
          <div class="text-align-center">
            <mat-icon class="contactus-icon email-icon-color"
              >mail_outline</mat-icon
            >
          </div>
          <div class="text-align-center">
            <h2 class="contact-us-font">ServicioAlCliente@sentrega.com</h2>
          </div>
        </div>
        <div fxLayout="column">
          <div class="text-align-center">
            <mat-icon class="contactus-icon whatsapp-icon-color">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                style="height: 54px"
              >
                <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                />
              </svg>
            </mat-icon>
          </div>
          <div class="text-align-center">
            <h2 class="contact-us-font">+3-461-014-7736</h2>
          </div>
        </div>
      </div>
      <div class="text-align-center followus-mt">
        <h1 class="siguenos">Follow Us</h1>
      </div>
      <div fxLayout="row" style="justify-content: center">
        <div class="siguenos-icon-mr">
          <a
            href="https://www.google.com"
            target="_blank"
            class="icon-font-size"
          >
            <mat-icon class="fa-twitter"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="followus-icons"
              >
                <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                />
              </svg>
            </mat-icon>
          </a>
        </div>
        <div class="siguenos-icon-ml">
          <a href="https://www.google.com" target="_blank">
            <mat-icon
              class="followus-icons fa-brands fa-facebook icon-font-size"
              >facebook</mat-icon
            >
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="mensaje == 'English'">
      <div class="text-align-center">
        <h1 class="contactus">Contactenos</h1>
      </div>
      <div fxLayout="column">
        <div fxLayout="column">
          <div class="text-align-center">
            <mat-icon class="contactus-icon email-icon-color"
              >mail_outline</mat-icon
            >
          </div>
          <div class="text-align-center">
            <h2 class="contact-us-font">ServicioAlCliente@sentrega.com</h2>
          </div>
        </div>
        <div fxLayout="column">
          <div class="text-align-center">
            <mat-icon class="contactus-icon whatsapp-icon-color">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                style="height: 54px"
              >
                <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                />
              </svg>
            </mat-icon>
          </div>
          <div class="text-align-center">
            <h2 class="contact-us-font">+3-461-014-7736</h2>
          </div>
        </div>
      </div>
      <div class="text-align-center followus-mt">
        <h1 class="siguenos">Siguenos</h1>
      </div>
      <div fxLayout="row" style="justify-content: center">
        <div class="siguenos-icon-mr">
          <a
            href="https://www.google.com"
            target="_blank"
            class="icon-font-size"
          >
            <mat-icon class="fa-twitter"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="followus-icons"
              >
                <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                />
              </svg>
            </mat-icon>
          </a>
        </div>
        <div class="siguenos-icon-ml">
          <a href="https://www.google.com" target="_blank">
            <mat-icon
              class="followus-icons fa-brands fa-facebook icon-font-size"
              >facebook</mat-icon
            >
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
