<div
  *ngIf="!mostrar"
  fxFlex="95%"
  fxFlex.gt-sm="75%"
  class="center-content-vertically"
>
  <div class="display-flex m-top-5p">
    <div>
      <h1 class="m-top-10px">Valoraciones</h1>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" class="width-100">
      <div>
        <button mat-icon-button color="primary" (click)="volver()">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutAlign="start start"
    fxLayoutAlign.gt-sm="start center"
    class="align-items-center m-top-4p"
  >
    <div class="display-flex align-itmes-center">
      <form>
        <div fxLayout="row" fxLayoutAlign="space-around">
          <mat-form-field>
            <input
              matInput
              #display
              placeholder="Valoraciones por usuario"
              [formControl]="displayName"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              (optionSelected)="search(display.value)"
            >
              <mat-option
                *ngFor="let name of filteredOptions | async"
                [value]="name.displayName"
              >
                {{ name.displayName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </form>
      <div style="display: none">
        <button
          mat-mini-fab
          class="custom-primary-btn margin-tl-8px"
          aria-label="Search button"
          (click)="search(display.value)"
        >
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <button mat-stroked-button color="primary" (click)="addValoracion()">
        <mat-icon>add</mat-icon>
        Agrega Valoración
      </button>
    </div>
  </div>
  <div fxLayout="row">
    <div
      *ngIf="
        deliveryDetails?.rating !== null && reviewSeleccionado.length !== 0
      "
    >
      <h2 style="margin-top: 10px">
        Valoración promedio {{ deliveryDetails?.rating.valoracion }}/{{ 5 }}
      </h2>
    </div>
  </div>
  <div>
    <div>
      <mat-card
        *ngFor="let review of reviewSeleccionado; index as i"
        class="mat-card-mt"
      >
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <div fxLayout="row" fxLayoutAlign="start">
            <mat-card-subtitle fxLayout="row" style="margin-bottom: 0px">
              <p style="font-size: 16px; margin-bottom: 8px">
                <span style="font-size: 14px; font-weight: bold">
                  <ngx-material-rating
                    [style.color]="'orange'"
                    [readonly]="'true'"
                    [(ngModel)]="review.valoracion"
                    [max]="5"
                  >
                  </ngx-material-rating>
                </span>
                <span> {{ review.valoracion }}/5 </span>
              </p>
            </mat-card-subtitle>
          </div>
        </div>
        <mat-card-content style="margin-bottom: 0px; margin-top: 8px">
          <div>
            {{ review?.comentario }}
          </div>
        </mat-card-content>
        <div fxLayoutAlign="end center">
          <p class="username-font">
            {{ review?.username }}
          </p>
        </div>
      </mat-card>
    </div>
  </div>
  <div *ngIf="reviewSeleccionado.length === 0" fxLayoutAlign="center center">
    <span class="font"> Este usuario no tiene valoraciones. </span>
  </div>
</div>
<div *ngIf="mostrar" class="width-100">
  <app-courier-details></app-courier-details>
</div>
