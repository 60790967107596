<mat-card>
  <mat-card-header>
    <div fxLayout="row">
      <mat-card-title class="encabezado">
        <label>Record de ganancias</label>
      </mat-card-title>
      <div class="volver">
        <button
          mat-mini-fab
          color="primary"
          style="background-color: #3f51b5 !important"
          (click)="volver()"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="row wrap">
      <mat-table
        bigitems
        [dataSource]="dataSourceDeliveryPayments"
        class="mat-table-responsibe"
        matSort
        (matSortChange)="sortData($event)"
      >
        <ng-container matColumnDef="codigo">
          <mat-header-cell *matHeaderCellDef mat-sort-header="deliverySaleCode">
            <b> Codigo </b></mat-header-cell
          >
          <mat-cell *matCellDef="let element">
            {{ element.deliverySaleCode }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="producto">
          <mat-header-cell *matHeaderCellDef class="alinear-a-la-derecha">
            <b> Producto vendido </b></mat-header-cell
          >
          <mat-cell *matCellDef="let element" class="alinear-a-la-derecha">
            {{ element.productPurshased }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="monto">
          <mat-header-cell
            *matHeaderCellDef
            class="alinear-a-la-derecha"
            mat-sort-header="deliveryUserMoney"
          >
            <b> Ganancia</b></mat-header-cell
          >
          <mat-cell *matCellDef="let element" class="alinear-a-la-derecha">
            {{ element.deliveryUserMoney }}
          </mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="displayedColumnsDelivery"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsDelivery"
        ></mat-row>
      </mat-table>
      <mat-paginator
        #paginatorDelivery
        [length]="5"
        [pageIndex]="0"
        [pageSize]="3"
        [pageSizeOptions]="[3, 5, 10]"
      >
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
