import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/User';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser$: Observable<User>;

  baseUrl = environment.apiUrl;
  decodedToken: any;
  jwtHelper = new JwtHelperService();
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser$ = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentUserId(): number {
    if (this.currentUserSubject.value) {
      return this.currentUserSubject.value.id;
    } else {
      return null;
    }
  }

  login(email: string, password: string): Observable<any> {
    return this.http
      .post(this.baseUrl + 'auth/login', { email, password })
      .pipe(
        map((user: User) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  register(username: string, password: string, email: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/register', {
      username,
      password,
      email,
    });
  }

  getTempPassword(email: string): Observable<any> {
    return this.http.get(this.baseUrl + 'auth/temppassword/' + email);
  }

  resetPassword(password: string, userId: number) {
    return this.http.put(this.baseUrl + 'auth/resetpassword', {
      password,
      userId,
    });
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  updateLocalStorageUser(user: User) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  verificarPass(email: string, password: string): Observable<any>{
    return this.http
    .post(this.baseUrl + 'auth/verificarPass', { email, password });    
  }

  updateUser(userId: number, username: string, email: string, password:string): Observable<any>{
    return this.http
    .post(this.baseUrl + 'auth/UpdateUser', { userId,username,email, password });    
  }
}
