<mat-card
  style="
    width: 100%;
    padding: 10px 0px 0px 15px;
    margin-bottom: 1%;
    border: 3px solid lightblue;
    margin-top: 1%;
  "
  fxLayout="row"
  fxLayout.lt-sm="column"
  fxLayoutAlign="start center"
  [formGroup]="searchForm"
>
  <div style="padding-top: 5px; width: 100%" fxLayout="row">
    <div style="width: 48%">
      <mat-form-field>
        <mat-label>Ciudad de Salida</mat-label>
        <input
          matInput
          ngx-google-places-autocomplete
          [options]="options"
          #placesRef="ngx-places"
          formControlName="from"
          placeholder="Departure"
          (onAddressChange)="handleAddressChange($event, 'from')"
        />
      </mat-form-field>
    </div>
    <div style="margin-left: 10px; width: 48%">
      <mat-form-field>
        <mat-label>Cliudad Destino</mat-label>
        <input
          matInput
          ngx-google-places-autocomplete
          [options]="options"
          #placesRef="ngx-places"
          formControlName="to"
          placeholder="Arrival"
          (onAddressChange)="handleAddressChange($event, 'to')"
        />
      </mat-form-field>
    </div>
  </div>
  <div style="padding-top: 5px" class="material-width" fxLayout="row">
    <div style="width: 50%">
      <mat-form-field style="width: 100%" class="form-input-font-size">
        <mat-label>Opciones de Busqueda</mat-label>
        <mat-select
          formControlName="toppings"
          (selectionChange)="updateOrderByList()"
          (openedChange)="setTodos($event)"
          multiple
        >
          <mat-option *ngFor="let topping of toppingList" [value]="topping">{{
            topping
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div style="width: 50%">
      <button
        fxShow
        fxHide.lt-sm="true"
        mat-mini-fab
        color="primary"
        style="background-color: #3babd0 !important"
        aria-label="Search button"
        [disabled]="!searchForm.valid"
        (click)="search()"
      >
        <mat-icon>search</mat-icon>
      </button>
      <button
        fxHide
        fxShow.lt-sm="true"
        style="
          background-color: #3babd0 !important;
          display: flex;
          margin-top: 5px;
        "
        mat-raised-button
        color="primary"
        (click)="search()"
        [disabled]="!searchForm.valid"
      >
        <mat-icon>search</mat-icon>
        Buscar Viajes
      </button>
    </div>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxShow="true"
    fxHide.lt-md="true"
  >
    <div style="padding-top: 5px; padding-right: 20px">
      <mat-form-field style="width: 250px" class="form-input-font-size">
        <mat-label>Ordenar por:</mat-label>
        <mat-select
          name="order"
          (ngModelChange)="changeSortByFilter()"
          formControlName="selectedSortBy"
        >
          <mat-option *ngFor="let order of sortByOptions" [value]="order">
            {{ order }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-card>
<div *ngIf="noResultsFound" class="landing-page-msg">
  <h1 class="su-busqueda-no-dio-resultado">
    Disculpe, su búsqueda no dio resultados.
  </h1>
</div>

<div
  *ngIf="
    (!(routes$ | async) || (routes$ | async).length === 0) &&
    !noResultsFound &&
    !this.currentUser &&
    !loading
  "
  class="landing-page-msg"
>
  <a [routerLink]="['/search-courier']">
    <img class="logo-img" src="/assets/Union-4.png" alt="logo img" />
  </a>
  <h1 class="desea-vender-libras-msg desea-vender-libras-mt">
    ¿Desea vender sus libras?
  </h1>
  <h1 class="desea-vender-libras-msg">
    ¡Por favor
    <strong
      ><a [routerLink]="['/register']" class="desea-vender-sus-libras-link"
        >registrese</a
      ></strong
    >
    y cree un viaje!
  </h1>
  <p style="font-size: 20px; color: #e34b14">
    Su viaje sera visible a otras personas que quieren comprar sus libras o
    productos.
  </p>
</div>
<div
  style="width: 100%"
  fxLayout="row wrap"
  fxLayout.lt-sm="column"
  style="justify-content: center"
>
  <mat-card
    *ngFor="let route of routes$ | async"
    fxFlex="1 1 32%"
    fxFlex.md="1 1 48%"
    fxFlex.sm="1 1 100%"
    fxLayoutAlign="center stretch"
    fxLayout="column"
    (click)="navigate(route)"
    style="cursor: pointer; margin: 5px"
  >
    <div>
      <div>
        <p style="font-size: 16px; margin-bottom: 5px">
          <span style="font-size: 14px; font-weight: bold"> &nbsp;Salida:</span>
          {{ route?.fromPlace.formattedAddress }}
        </p>
        <p style="font-size: 16px; margin-bottom: 8px">
          <span style="font-size: 14px; font-weight: bold"> Destino:</span>
          {{ route?.toPlace.formattedAddress }}
        </p>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <div>
          <mat-card-subtitle fxLayout="row" style="margin-bottom: 0px">
            <div
              *ngIf="route?.account.profileImageVirtualPath === null"
              mat-card-avatar
              class="example-header-image"
            ></div>
            <div *ngIf="route?.account.profileImageVirtualPath !== null">
              <img
                mat-card-avatar
                src="{{ baseUrl }}{{
                  route?.account.profileImageVirtualPath
                }}?{{ time }}"
              />
            </div>
            <div fxLayout="column" style="margin-left: 8px">
              <div>{{ route?.account.displayName }}</div>
              <div class="mat-icon-div">
                <ng-container>
                  <div
                    *ngIf="route?.rating !== null"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    style="white-space: nowrap"
                  >
                    <div>
                      <ngx-material-rating
                        [style.color]="'orange'"
                        [readonly]="'true'"
                        [(ngModel)]="route?.rating.valoracion"
                        [max]="5"
                      >
                      </ngx-material-rating>
                    </div>
                    <span> {{ route?.rating.valoracion }}/{{ 5 }} </span>
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-card-subtitle>
        </div>
        <div>
          <span class="material-icons">
            <span
              class="material-icons"
              style="font-size: 25px; color: #85527e"
            >
              flight_takeoff
            </span>
          </span>
        </div>
      </div>
    </div>
    <mat-card-content style="margin-bottom: 0px; margin-top: 8px">
      <table>
        <tr *ngIf="route?.departure !== null">
          <td>Salida</td>
          <td>{{ route?.departure | date }}</td>
        </tr>
        <tr *ngIf="route?.departure === null">
          <td>Salida</td>
          <td><strong>Todos los Dias</strong></td>
        </tr>
        <tr>
          <td>Precio/Libra</td>
          <td>
            <b>${{ route?.price }}</b>
          </td>
        </tr>
        <tr>
          <td>
            Por cada: <b>${{ route?.porCada }}</b>
          </td>
          <td>
            Se entrega: <b>${{ route?.entrego }}</b>
          </td>
        </tr>
        <tr>
          <td>
            Telefonos a llevar: <b>{{ route?.telefonosALlevar }}</b>
          </td>
          <td>
            Precio por telefono: <b>${{ route?.precioPorTelefono }}</b>
          </td>
        </tr>
      </table>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="end">
      <div class="mr-r">
        <button mat-button color="primary" (click)="navigate(route)">
          Ver Detalles
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
<mat-paginator
  [fxShow]="routes && routes.length > 0 && routes.length > 6"
  [length]="routes.length"
  [pageSize]="6"
  [pageSizeOptions]="[6, 12, 24, 100]"
  aria-label="Select page"
  style="position: relative; margin-top: 10px; margin-bottom: 20px"
>
</mat-paginator>

<div
  *ngIf="loading"
  fxLayoutAlign="center"
  style="position: absolute; top: 40%; right: 35%; left: 35%"
>
  <mat-spinner></mat-spinner>
</div>
