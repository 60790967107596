<mat-card
  class="width-100"
  style="padding: 0px; border: 3px solid lightblue; margin-top: 1%"
  fxLayout="row"
  fxLayoutGap="10px"
  fxLayoutAlign="start center"
>
  <div style="padding-left: 10px">
    <button mat-raised-button color="primary" (click)="addNewRoute()">
      <mat-icon>add</mat-icon>
      Agregar Viaje
    </button>
  </div>
  <div>
    <button
      mat-raised-button
      color="primary"
      (click)="chargingDelivery()"
      [hidden]="visible"
    >
      <mat-icon>payment</mat-icon>
      Cobrar Envios
    </button>
  </div>
  <div>
    <button
      mat-raised-button
      color="primary"
      (click)="totalAmount()"
      [hidden]="visible"
    >
      <mat-icon>attach_money</mat-icon>
      Ver Ganancias
    </button>
  </div>

  <div
    class="width-100"
    style="padding-top: 5px; padding-right: 30px; max-width: 250px"
  >
    <mat-form-field class="form-input-font-size width-100">
      <mat-label>Ordenar Por:</mat-label>
      <mat-select
        (ngModelChange)="changeDeliveryOrder()"
        [(ngModel)]="selectedOrder"
        name="order"
      >
        <mat-option *ngFor="let order of deliverlyListOrder" [value]="order">
          {{ order }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-card>
<div
  class="width-100"
  fxLayout="row wrap"
  fxLayout.lt-sm="column"
  style="justify-content: center; padding-top: 10px"
>
  <ng-container *ngFor="let route of routes">
    <div fxFlex="1 1 33.3333%" fxLayoutAlign="center center">
      <app-delivery-route
        [isUserView]="false"
        (deliveryRouteDeleted)="deleteDeliveryRoute($event)"
        style="width: 96%"
        [route]="route"
      ></app-delivery-route>
    </div>
  </ng-container>
</div>

<div
  *ngIf="isLoading"
  fxLayoutAlign="center"
  style="position: absolute; top: 40%; right: 35%; left: 35%"
>
  <mat-spinner></mat-spinner>
</div>
